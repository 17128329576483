import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Header from "../components/header";
import { obtenerParametrosOtorgante } from '../services/api.js';
import { configStyle, obtenerValorConfig, positionDiv } from '../services/configStyle';
import Footer from "../components/footer";
import { v4 as uuidv4 } from 'uuid';
import { isChrome, isEdge, isMobile, isIOS, isSafari, isAndroid, deviceDetect } from 'react-device-detect';
import { hayExcepcion, initStatus, evento, statusError } from '../services/data';
import Ayuda from '../pages/ayuda';
import Loader from "../components/loader";

const Inicio = () => {
    const { apikey } = useParams();
    //const [loading, setLoading] = useState(false);
    const [apiKey, setApiKey] = useState('')
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [notSupported, setNotSupported] = useState(false);
    const [notSupportediOS, setNotSupportediOS] = useState(false);
    const [hayCookies] = useState(navigator.cookieEnabled);
    const [isCorrectApikey, setCorrectApikey] = useState(true);
    const divFooterRef = useRef(null);
    const [isOpenByPivot, setIsOpenByPivot] = useState(false);
    const [nombreOtorgante, setNombreOtorgante] = useState(null);
    const history = useHistory();
    const [showHelp, setShowHelp] = useState(false);
    const [start, setStart] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const itemsDeleteLS = [
            "codes",
            "ocrFront",
            "ocrBack",
            "time",
            "bloqueo",
            "face",
            "fotos",
            "enlace",
            "data_user",
            "movil",
            "dataOtorgante",
            "apikeyOtorgante",
            "uuidTrx",
            "fechaVigencia",
            "uuidOtorgante",
            "fields",
            "links",
            "flag",
            "correoElectronico",
            "completado",
            "validacionRENAPO",
            "jobRENAPO",
            "validacionINE",
            "jobINE",
        ];
        //console.log("Version 1.04.27");
        if (hayCookies) {
            let uuidUser = localStorage.getItem("uuidUser");
            if (uuidUser === null) {
                if (new URL(window.location).searchParams.get("cliente")) {
                    localStorage.setItem("uuidUser", new URL(window.location).searchParams.get("cliente"))
                } else {
                    let newUuid = uuidv4();
                    localStorage.setItem("uuidUser", newUuid);
                }
            } else {
                if (new URL(window.location).searchParams.get("cliente")) {
                    localStorage.setItem("uuidUser", new URL(window.location).searchParams.get("cliente"))
                }
            }

            itemsDeleteLS.forEach((element, indice) => {
                localStorage.removeItem(element);
            });

            let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
            if (apikeyOtorgante !== null) {
                setApiKey(apikeyOtorgante)
            } else {
                setApiKey(apikey)
            }
            localStorage.setItem("apikeyOtorgante", apikey)
            if (new URL(window.location).searchParams.get("uuidTrx")) {
                //console.log("hay uuidTrx");
                localStorage.setItem("uuidTrx", new URL(window.location).searchParams.get("uuidTrx"))
            } else {
                let trx_id = uuidv4();
                localStorage.setItem("uuidTrx", trx_id);
            }
        }
        if (window.opener) {
            setIsOpenByPivot(true);
        }
    }, [apikey])

    useEffect(() => {
        //initStatus()
        console.log("Version 1.7.3_v1.1");
        localStorage.setItem("completado", "false");
        obtenerParametros();
        //let divFooter = divFooterRef.current;
        //divFooter.style.position = positionDiv(window.innerWidth, window.innerHeight);
    }, [])

    useEffect(() => {
        //console.log("actualizando dataOtorgante");
        setDataOtorgante(dataOtorgante)
        configStyle(dataOtorgante)
        if (hayCookies) {
            localStorage.setItem("dataOtorgante", JSON.stringify(dataOtorgante))
        }
        let logo = (obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', ""));
        ////console.log("logo", logo);
        document.getElementById("imagenLogo").src = logo;
        let ID_OTORGANTE = (obtenerValorConfig(dataOtorgante, 'ID_OTORGANTE', ""));
        //console.log("ID_OTORGANTE",ID_OTORGANTE);
        if (ID_OTORGANTE !== "") {
            localStorage.setItem("uuidOtorgante", ID_OTORGANTE)
        } else {
            //console.log("no hay uuid otrogante");
        }
    }, [dataOtorgante])

    const obtenerParametros = async () => {
        let tipo = '';
        let informacion = {};
        //setLoading(true)
        obtenerParametrosOtorgante(apikey, apiKey).then((data) => {
            if (data.status === 200) {
                setDataOtorgante(data.data.payload);
                setNombreOtorgante(obtenerValorConfig(data.data.payload, 'NOMBRE_CORTO', ""));
                informacion.status = data.status;
                informacion.payload = data.data.payload;
                evento('Obtener parametros otrogante', 'Success', informacion, true);
                setLoading(false);
                setStart(true);
            }
            //setLoading(false)
        }).catch((error) => {
            //console.log('Error', error);
            setCorrectApikey(false);
            statusError('inicio', 'Error al obtener los parámetros del otorgantes', 'error');
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Obtener parametros otrogante', tipo, informacion, false);
            setLoading(false);
            //setLoading(false)
        })
    }

    useEffect(() => {
        //console.log("isAndroid:", isAndroid);
        //console.log("isMobile:", isMobile);
        if (!isMobile && !isEdge && !isChrome) {
            //console.log("Desktop, not supported browser");
            setNotSupported(true);
        } else if (isMobile && isIOS && !isSafari) {
            //console.log("iOS, not supported browser");
            setNotSupportediOS(true);
        } /*else if (isMobile && !isIOS && (!isFirefox && !isChromium)) {
            //console.log("Android, not supported browser");
            setNotSupported(true);
        }*/
        evento('Dispositvo', 'Step', deviceDetect(), true);
        evento('Dimensiones viewport', 'Información', {innerWidth: window.innerWidth, innerHeight: window.innerHeight}, true);
    }, [])

    const reload = () => {
        window.location.reload();
    }

    // window.addEventListener('resize', () => {
    //     let divFooter = document.querySelector('#divFooter');
    //     divFooter.style.position = positionDiv(window.innerWidth, window.innerHeight);
    // });

    useEffect(() => {
        if (isOpenByPivot) {
            initStatus(nombreOtorgante);
        }
    }, [isOpenByPivot])

    const startFlow = () => {
        evento('Inicio', 'Click', { description: 'EMPEZAR' }, true);
        if (!isOpenByPivot) {
            initStatus(nombreOtorgante);
        }
        let nextStep = (obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_1', "true") === "true") ? "/terminos" : "/preparacion_identificacion";
        setTimeout(() => {
            history.push(nextStep);
        }, 200);
    }

    return (
        <>
            <div className="main_gradient">
                <div className="container_grid">
                    <Header show={() => setShowHelp(true)} />
                    <div>
                        <div className="main_text_container custom_padding">
                            <h1 className="animate__animated animate__fadeIn animate__delay-1s" style={{ marginBottom: '0' }}>Inicia tu solicitud</h1>
                            <div className="animate__animated animate__fadeIn animate__delay-2s">Para validar tu identidad necesitas lo siguiente:
                                <br />
                                <br />
                                <ul className="nomrgnnopdng animate__animated animate__fadeIn animate__delay-2s">
                                    <li><b>Una identificación oficial vigente:</b>
                                        {(hayExcepcion()) ?
                                            <ul className="nomrgnnopdng">
                                                <li>DNI</li>
                                            </ul>
                                            :
                                            <ul className="sub_list">
                                                <li className="spaceLi"><em>INE / IFE</em></li>
                                                <li className="spaceLi"><em>Pasaporte Mexicano</em></li>
                                                <li className="spaceLi"><em>Documento migratorio FM2</em></li>
                                            </ul>
                                        }
                                    </li>
                                    <li>Dar <b>acceso a la cámara</b></li>
                                    <li>Una <b>buena conexión</b>  a internet</li>
                                    <br />
                                    <li><b>Evita lo siguiente:</b></li>
                                </ul>
                                <div className="row id_type_bx">
                                    <div className="col-6">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <h6>Cara en ID cubierta</h6>
                                            <div className="maskface id_cntr"></div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <h6>Audífonos conectados</h6>
                                            <div className="maskface headphones"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="action_buttons">
                            {/* <Link to={(obtenerValorConfig(dataOtorgante, 'ENABLE_CONSENTIMIENTO_1', "true") === "true") ? "/terminos" : "/preparacion_identificacion"} className="btn btn-raised btn-primary forcewidth100 main_bg_color animated fadeIn delay-3s">
                                EMPEZAR</Link> */}
                            <button onClick={startFlow} className="btn btn-raised btn-primary forcewidth100 main_bg_color animated fadeIn delay-3s" disabled={!start}>EMPEZAR</button>
                        </div>
                    </div>

                    {/* <div className="div_bottom" id="divFooter" ref={divFooterRef}>
                        <Footer relative="true"/>
                    </div> */}
                </div>
                {((notSupported) ? <div className="browser_not_comp show_me_bx">
                    <div className="top_bx">
                        <img src="images/browser_alert_icon.svg" alt="" />
                        <h4>Lo sentimos tu navegador actual no es <br />
                            compatible con nuestra plataforma.</h4>
                        <div className="divider"></div>
                    </div>
                    <div className="bottom_bx">
                        <p><small>Te sugerimos utilizar o descargar alguna de estas opciones.</small></p>
                        <div className={["browser_bx", (isMobile ? "ios_browser" : "")].join(" ")}>
                            <img src="images/browser_chrome.png" width="96" alt="" />
                            <a href="https://www.google.com/chrome/">Chrome</a>
                            <small>Google</small>
                        </div>
                        <div className={["browser_bx", (isMobile ? "ios_browser" : "")].join(" ")}>
                            <img src="images/browser_edge.png" width="96" alt="" />
                            <a href="https://www.microsoft.com/en-us/edge">Edge</a>
                            <small>Microsoft</small>
                        </div>
                    </div>

                </div> : (""))}
                {((notSupportediOS) ? <div className="browser_not_comp show_me_bx">
                    <div className="top_bx">
                        <img src="images/browser_alert_icon.svg" alt="" />
                        <h4>Lo sentimos tu navegador actual no es <br />
                            compatible con nuestra plataforma.</h4>
                        <div className="divider"></div>
                    </div>
                    <div className="bottom_bx">
                        <p><small>Te sugerimos utilizar o descargar alguna de estas opciones.</small></p>
                        <div className="browser_bx ios_browser">
                            <img src="images/browser_ios.png" width="96" alt="" />
                            <a href="#!">Safari</a>
                            <small>Apple</small>
                        </div>
                    </div>

                </div> : (""))}
                {
                    (!hayCookies) ? (<div className="err_finale  animated slideInUp">
                        <div className="center_checks">
                            <h5>Verifica tu navegador</h5>
                            <p>Para acceder a este sitio es necesario habilitar el uso de Cookies
                            </p>
                            <br />
                            <div className="action_buttons noscroll_screen">
                                <button type="button" onClick={() => reload()} className="btn btn-raised btn-primary forcewidth100 main_bg_color">REINTENTAR</button>
                            </div>
                        </div>
                    </div>) : ("")}

                {
                    (!isCorrectApikey) ? (<div className="err_finale  animated slideInUp">
                        <div className="center_checks">
                            <h5>Verifica tu URL</h5>
                            <p>Lo sentimos la dirección que buscas no se encuentra o es incorrecta, por favor verifica la información e intenta nuevamente.
                            </p>
                            <br />
                            <div className="action_buttons noscroll_screen">
                                <button type="button" onClick={() => reload()} className="btn btn-raised btn-primary forcewidth100 main_bg_color">REINTENTAR</button>
                            </div>
                        </div>
                    </div>) : ("")}
            </div>
            {showHelp && <Ayuda hide={() => setShowHelp(false)} />}
            {(loading) && <Loader />}
        </>
    )
}

export default Inicio