import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { configStyle, obtenerValorConfig } from '../../services/configStyle'
import NumberFormat from 'react-number-format';
import { hayExcepcion, agregarInfo, status, statusData, esEditable, formatoFechaOcr, formatoFechaCuatroDigitos, validarCampo, validarValoresFormulario, evento, statusError } from '../../services/data';
import { enviarRenapo } from '../../services/api';


let FormularioPasaporte = (props) => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataOCRBack, setDataOCRBack] = useState({})
    const [dataOCRFront, setDataOCRFront] = useState({})
    const [focusNombre, setFocusNombre] = useState(false)
    const [focusNacimiento, setFocusNacimiento] = useState(false)
    const [focusCurp, setFocusCurp] = useState(false)
    const [focusVigencia, setFocusVigencia] = useState(false);
    const [dataUser, setDataUser] = React.useState([])
    const [inputActive, setInputActive] = useState(false)
    const [esPassport, setEsPassport] = useState(false);
    const [esCLaboral, setCLaboral] = useState(true);
    const [noOCR, setNoOCR] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [inputsValue, setInputsValue] = useState({
        nombres: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        fechaNacimiento: '',
        vigenciaPasaporte: '',
        curp: ''
    });
    const [inputsErrors, setInputsErrors] = useState({});
    const [esPermanente, setEsPermanente] = useState(false);
    const [esMigratoria, setEsMigratoria] = useState(false);
    const [fechaNacimiento, setfechaNacimiento] = useState("")
    const [fechaVigencia, setfechaVigencia] = useState("")
    const [esVigente, setEsVigente] = useState(true)
    const [focusApellidoPaterno, setFocusApellidoPaterno] = useState(false);
    const [focusApellidoMaterno, setFocusApellidoMaterno] = useState(false);
    const [sendForm, setSendForm] = useState(false);
    const [encolarRENAPO, setEncolarRENAPO] = useState(false);

    useEffect(() => {

        let no_OCR = localStorage.getItem("intentsData")
        if (no_OCR !== null) {
            if (no_OCR === "OCR")
                setNoOCR(true)
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        let dataOCRFrontLS = localStorage.getItem("ocrFront")
        const ocrFront = JSON.parse(dataOCRFrontLS)
        if (dataOCRFrontLS) {
            setDataOCRFront(ocrFront);
            if (ocrFront.nombres)
                setInputsValue(inputsValue => ({ ...inputsValue, ["nombres"]: ocrFront.nombres || '' }));
            if (ocrFront.apellidoPaterno)
                setInputsValue(inputsValue => ({ ...inputsValue, ["apellidoPaterno"]: ocrFront.apellidoPaterno || '' }));
            if (ocrFront.apellidoMaterno)
                setInputsValue(inputsValue => ({ ...inputsValue, ["apellidoMaterno"]: ocrFront.apellidoMaterno || '' }));
            if (ocrFront.fechaNacimiento) {
                if (ocrFront.fechaNacimiento.length > 6 && ocrFront.fechaNacimiento.length < 10) {
                    let fechaOcr = formatoFechaCuatroDigitos(ocrFront.fechaNacimiento);
                    let fechaNacimiento = formatoFechaOcr(fechaOcr);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento || '' }));
                } else {
                    let fechaNacimiento = formatoFechaOcr(ocrFront.fechaNacimiento);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento || '' }));
                }
                setfechaNacimiento(ocrFront.fechaNacimiento)
            }
            if (ocrFront.curp)
                if (ocrFront.curp.length > 14)
                    setInputsValue(inputsValue => ({ ...inputsValue, ["curp"]: (ocrFront.curp).trim() || '' }));
            let vigenciaC = ''
            if (ocrFront.vigencia) {
                if (ocrFront.identificacionPasaporte) {
                    if (ocrFront.vigencia.includes(' '))
                        vigenciaC = ocrFront.vigencia.replaceAll(' ', '/')
                    else
                        vigenciaC = ocrFront.vigencia
                    if (ocrFront.identificacionPasaporte) {
                        if (ocrFront.vigencia.includes(' '))
                            vigenciaC = ocrFront.vigencia.replaceAll(' ', '/')
                        else
                            vigenciaC = ocrFront.vigencia
                    }
                } else if (ocrFront.identificacionMigratoria) {
                    setEsMigratoria(true);
                    if (ocrFront.documentoPermanente) {
                        setEsPermanente(true);
                        delete inputsValue.vigenciaPasaporte;
                        delete inputsErrors.vigenciaPasaporte;
                    } else {
                        vigenciaC = "20" + ocrFront.vigencia.substr(-2);
                    }
                }
                setfechaVigencia(ocrFront.vigencia)
            }

            if (ocrFront.documentoPermanente) {
                setEsPermanente(true);
                delete inputsValue.vigenciaPasaporte;
                delete inputsErrors.vigenciaPasaporte;
            } else {
                setInputsValue(inputsValue => ({ ...inputsValue, ["vigenciaPasaporte"]: vigenciaC || '' }));
            }
        }

    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante]);

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser]);

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoPersonal = infoFormulario;
        statusData("datos_personales_ocr", jsonObj)
    }

    const handleChange = (event) => {
        event.persist();
        let value = event.target.value.trim().toUpperCase();
        let validacion = validarCampo(value, event.target.name);
        if (event.target.name === "fechaNacimiento") {
            setfechaNacimiento(value)
        }
        if (event.target.name === "vigenciaPasaporte") {
            setfechaVigencia(value)
        }
        if (event.target.name === 'curp') {
            let maxLength = 18;
            if (event.target.value.length > maxLength) {
                value = value.substr(0, maxLength);
                event.target.value = value;
            }
            validacion = validarCampo(value, event.target.name);
        } else {
            validacion = validarCampo(value, event.target.name);
        }
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }));
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }

    };

    const validarFormulario = (event) => {
        if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        setSendForm(true);
        let continuar = validarValoresFormulario(inputsValue);
        if (continuar.correcto) {
            if (obtenerValorConfig(dataOtorgante, 'ENABLE_RENAPO', "false") === "true") {
                let tipo = '';
                let informacion = {};
                enviarRenapo(inputsValue.curp).then(({ status, data: { payload }, data: { payload: { job_id } } }) => {
                    localStorage.setItem("jobRENAPO", job_id);
                    localStorage.setItem("validacionRENAPO", false);
                    informacion.status = status;
                    informacion.payload = payload;
                    evento('Encolar petición RENAPO', 'Success', informacion, true);
                }).catch(error => {
                    if (error.response) {
                        let { data: { message_client }, status } = error.response
                        informacion.mensaje = message_client;
                        informacion.status = status;
                        tipo = 'Error';
                    } else {
                        informacion.error = error.toString();
                        tipo = 'Exception';
                    }
                    evento('Encolar petición RENAPO', tipo, informacion, false);
                }).finally(() => {
                    setEncolarRENAPO(true);
                });
            }

            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'OK' }, true);
            let infoFormulario = []
            agregarInfo(dataUser, { description: "nombres", value: inputsValue.nombres });
            infoFormulario.push({ description: "nombres", value: inputsValue.nombres })
            agregarInfo(dataUser, { description: 'apellidoPaterno', value: inputsValue.apellidoPaterno });
            infoFormulario.push({ description: "apellidoPaterno", value: inputsValue.apellidoPaterno })
            agregarInfo(dataUser, { description: "apellidoMaterno", value: inputsValue.apellidoMaterno });
            infoFormulario.push({ description: "apellidoMaterno", value: inputsValue.apellidoMaterno });
            let nombreCompleto = `${inputsValue.nombres} ${inputsValue.apellidoPaterno} ${inputsValue.apellidoMaterno}`
            agregarInfo(dataUser, { description: "nombreCompleto", value: nombreCompleto });
            infoFormulario.push({ description: "nombreCompleto", value: nombreCompleto });
            let fechaNacimiento = document.getElementById("fechaNacimiento").value;
            agregarInfo(dataUser, { description: "nacimiento", value: fechaNacimiento });
            infoFormulario.push({ description: "nacimiento", value: fechaNacimiento })
            agregarInfo(dataUser, { description: "curp", value: inputsValue.curp });
            infoFormulario.push({ description: "curp", value: inputsValue.curp })
            let inputVigencia = document.getElementById("vigenciaPasaporte");
            if (inputVigencia) {
                infoFormulario.push({ description: "vigencia", value: inputVigencia.value })
                agregarInfo(dataUser, { description: "vigencia", value: inputVigencia.value });
            } else {
                infoFormulario.push({ description: "vigencia", value: 'PERMANENTE' })
                agregarInfo(dataUser, { description: "vigencia", value: 'PERMANENTE' });
            }
            sendData(infoFormulario);
            //console.log("Vigencia válida");
            setTimeout(() => {
                if (hayExcepcion()) {
                    history.push('/clausula');
                } else {
                    history.push('/identificacion_edit')
                }
            }, 300)
        } else {
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'Error', Errores: continuar.errores }, false);
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...continuar.errores }));
            setSendForm(false);
        }
    }

    const terminarFlujo = async (event) => {
        if (event) event.preventDefault();
        //console.log("entre a status cancelar");
        let data = "Fecha de vigencia no validá";
        statusError("datos_personales_ocr", data, "cancelado");
        evento('Datos personales OCR', 'Click', { description: 'TERMINAR', status: data }, true);
        if (!window.opener) {
            setTimeout(() => {
                window.close()
                history.push("/" + apiKey);
            }, 300);
        }
    }

    return (
        <Fragment>
            <form id="FormularioPasaporte">
                <div className="ocr_data_display animate__animated">
                    <div className={["form-group", "bmd-form-group", ((focusNombre || inputsValue.nombres !== "") ? "is-focused" : ""), inputsErrors.nombres && 'error'].join(" ")}>
                        <label htmlFor="nombres" className="bmd-label-floating">Nombre(s):</label>
                        <input type="text" className="form-control none_border" id="nombres" name="nombres" defaultValue={inputsValue.nombres} onChange={handleChange} onFocus={e => {
                            setFocusNombre(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (inputsValue.nombres === "") {
                                setFocusNombre(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.nombres && (
                            <span id="ht-nombres" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nombres}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusApellidoPaterno || inputsValue.apellidoPaterno !== "") ? "is-focused" : ""), inputsErrors.apellidoPaterno && 'error'].join(" ")}>
                        <label htmlFor="apellidoPaterno" className="bmd-label-floating">Apellido Paterno:</label>
                        <input type="text" className="form-control none_border" id="apellidoPaterno" name="apellidoPaterno" defaultValue={inputsValue.apellidoPaterno} onChange={handleChange} onFocus={e => {
                            setFocusApellidoPaterno(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (inputsValue.nombres === "") {
                                setFocusApellidoPaterno(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.apellidoPaterno && (
                            <span id="ht-apellidoPaterno" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.apellidoPaterno}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusApellidoMaterno || inputsValue.apellidoMaterno !== "") ? "is-focused" : ""), inputsErrors.apellidoMaterno && 'error'].join(" ")}>
                        <label htmlFor="apellidoMaterno" className="bmd-label-floating">Apellido Materno:</label>
                        <input type="text" className="form-control none_border" id="apellidoMaterno" name="apellidoMaterno" defaultValue={inputsValue.apellidoMaterno} onChange={handleChange} onFocus={e => {
                            setFocusApellidoMaterno(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (inputsValue.apellidoMaterno === "") {
                                setFocusApellidoMaterno(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.apellidoMaterno && (
                            <span id="ht-apellidoMaterno" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.apellidoMaterno}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusNacimiento || inputsValue.fechaNacimiento !== "") ? "is-focused" : ""), inputsErrors.fechaNacimiento && 'error'].join(" ")}>
                        <label htmlFor="fechaNacimiento" className="bmd-label-floating">Fecha de nacimiento <small>(DD/MM/AAAA)</small>:</label>
                        <NumberFormat format="##/##/####" className="form-control none_border" id="fechaNacimiento" name="fechaNacimiento" defaultValue={inputsValue.fechaNacimiento} value={fechaNacimiento} onChange={handleChange} onFocus={e => {
                            setFocusNacimiento(true)
                            setInputActive(true)
                        }
                        } onBlur={e => {
                            if (inputsValue.fechaNacimiento === "") {
                                setFocusNacimiento(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.fechaNacimiento && (
                            <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaNacimiento}</span>
                        )}
                    </div>
                    {!esPermanente &&
                        <div className={["form-group", "bmd-form-group", ((focusVigencia || inputsValue.vigenciaPasaporte !== "") ? "is-focused" : ""), inputsErrors.vigenciaPasaporte && 'error'].join(" ")}>
                            <label htmlFor="vigenciaPasaporte" className="bmd-label-floating">Vencimiento identificación <small>(DD/MM/AAAA)</small>:</label>
                            <NumberFormat format="##/##/####" className="form-control none_border" id="vigenciaPasaporte" name="vigenciaPasaporte" defaultValue={inputsValue.vigenciaPasaporte} value={fechaVigencia} onChange={handleChange} onFocus={e => {
                                setFocusVigencia(true)
                                setInputActive(true)
                            }} onBlur={e => {
                                if (inputsValue.vigenciaPasaporte === "") {
                                    setFocusVigencia(false)
                                }
                                setInputActive(false)
                            }} />
                            {inputsErrors.vigenciaPasaporte && (
                                <span id="ht-vigencia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.vigenciaPasaporte}</span>
                            )}
                        </div>
                    }
                    <div className={["form-group", "bmd-form-group", ((focusCurp || inputsValue.curp !== "") ? "is-focused" : ""), inputsErrors.curp && 'error'].join(" ")}>
                        <label htmlFor="curp" className="bmd-label-floating">CURP</label>
                        {
                            (esMigratoria) ?
                                <input type="text" className="form-control uppercase none_border" id="curp" name="curp" defaultValue={inputsValue.curp} onChange={handleChange} onFocus={e => {
                                    setFocusCurp(true)
                                    setInputActive(true)
                                }} onBlur={e => {
                                    if (inputsValue.curp === "") {
                                        setFocusCurp(false)
                                    }
                                    setInputActive(false)
                                }} maxLength="19" />
                                :
                                <input type="text" className="form-control uppercase none_border" id="curp" name="curp" defaultValue={inputsValue.curp} onChange={handleChange} onFocus={e => {
                                    setFocusCurp(true)
                                    setInputActive(true)
                                }} onBlur={e => {
                                    if (inputsValue.curp === "") {
                                        setFocusCurp(false)
                                    }
                                    setInputActive(false)
                                }} />
                        }
                        {inputsErrors.curp && (
                            <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.curp}</span>
                        )}
                    </div>
                </div>
                {(!esVigente) ? (
                    <div className="err_finale  animated slideInUp">
                        <div className="center_checks">
                            <h5>Identificación no valida </h5>
                            <p>La <b>vigencia</b> de tu identificación <b>{inputsValue.vigencia}</b> no es valida, por favor <b>renueva tu identificación</b> e intenta otro día, si crees que es un error <b>intenta capturarla nuevamente</b>.
                            </p>
                            <br />
                            <div className="action_buttons noscroll_screen">
                                <button className="btn btn-secondary forcewidth100" onClick={event => terminarFlujo(event)}>TERMINAR</button>
                                {/* <Link to={{
                                    pathname: "/" + apiKey
                                }} className="btn btn-secondary forcewidth100" onClick={e => evento('Datos personales OCR', 'Click', { description: 'TERMINAR' }, true)}>TERMINAR</Link> */}
                                <Link to={{
                                    pathname: "/identificacion",
                                    state: { passport: esPassport }
                                }} className="btn btn-raised btn-primary forcewidth100 main_bg_color" onClick={e => evento('Datos personales OCR', 'Click', { description: 'CAPTURAR NUEVAMENTE' }, true)}>CAPTURAR NUEVAMENTE</Link>
                            </div>
                        </div>
                    </div>) : ("")}
                <div className="action_buttons animate__animated">
                    <button type="button" className={["btn btn-raised btn-primary forcewidth100 main_bg_color"].join(" ")} onClick={event => validarFormulario(event)}>MIS DATOS SON CORRECTOS</button>
                    <Link to={{
                        pathname: "/identificacion",
                        state: { passport: esPassport }
                    }} className="btn btn-primary forcewidth100 main_color" onClick={e => evento('Datos personales OCR', 'Click', { description: 'CAPTURAR NUEVAMENTE' }, true)}>CAPTURAR NUEVAMENTE</Link>
                </div>
            </form>
        </Fragment>
    )
}

export default FormularioPasaporte