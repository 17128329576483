/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import 'webrtc-adapter';
import { configStyle, obtenerValorConfig, positionDiv } from '../services/configStyle';
import LoaderOCR from "../components/loaderOCR";
import { obtenerOCR } from '../services/api.js';
import { isIOS, isMobileSafari, isMobile, withOrientationChange } from 'react-device-detect';
import { hayExcepcion, status, statusError, statusData, statusReintento, evento, flowIncompleted } from '../services/data'
import CropPhoto from './photoPreview';
import Header from "../components/headerNA";
import Ayuda from '../pages/ayuda';

class CameraPhotoIOS extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    state = {
        loading: false,
        uuid: null,
        dataUser: [],
        dataOtorgante: [],
        dataOCRFront: {},
        isStart: true,
        side: "front",
        proccessing: false,
        desktop: false,
        altura: 960,
        ancho: 720,
        showIdOverlay: false,
        intentsFront: 0,
        intentsBack: 0,
        apikey: "",
        hasResponse: false,
        hasFace: false,
        hasTextFront: false,
        hasTextBack: false,
        isPassport: false,
        isHoloInstructions: true,
        showHoloAnimation: true,
        intentsData: 0,
        errorF: "",
        errorMsg: false,
        errorVideo: false,
        streamStarted: false,
        pathname: "",
        previewId: false,
        imageUrl: '',
        showHelp: false,
        capturing: false,
        intentos: 0,
        errorCapture: false,
        uuidTrx: null,
    }

    componentDidMount() {
        const component = this;
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            this.setState({ apikey: apikeyOtorgante })
        }
        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            this.setState({ uuid: uuidUser })
        }
        let uuidTrx = localStorage.getItem("uuidTrx");
        if (uuidTrx) {
            this.setState({ uuidTrx: uuidTrx });
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            this.setState({ dataUser: JSON.parse(dataUserLS) })
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            this.setState({ dataOtorgante: JSON.parse(dataOtorganteLS) })
            configStyle(dataOtorganteJSON)
        }
        let bloqueo = localStorage.getItem("bloqueo");
        if (bloqueo === null) {
            localStorage.setItem("bloqueo", "false");
        } else {
            if (bloqueo == 'true') {
                this.state.intentsFront = Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "99"));
                //console.log("intentsFront: " + this.state.intentsFront);
            }
        }
        this.state.pathname = window.location.pathname;
        //console.log("bloque: " + bloqueo);
        if (!isMobile) {
            //console.log("es desktop");
            this.state.desktop = true;
            this.state.altura = 720;
            this.state.ancho = 960;
            this.setState({ showHoloAnimation: false })
            this.setState({ isHoloInstructions: false, isStart: true })
            var element = document.getElementById("video_wrt");
            element.classList.add("myVideo_rotate");
        } else {
            if (hayExcepcion()) {
                this.setState({ isHoloInstructions: false, isStart: true, showHoloAnimation: false })
            }
        }

        localStorage.setItem("ocrFrontOk", "0")
        localStorage.setItem("ocrBackOk", "0")
        status("captura_id_page", "Captura ID");

        let divFooter = document.querySelector('#div-buttons-calibrate');
        divFooter.style.position = positionDiv(window.innerWidth, window.innerHeight);

        this.setState({ intentos: Number(obtenerValorConfig(JSON.parse(dataOtorganteLS), "INTENTOS_ID_FRONTAL", "99")) });
    }

    start = (deviceFuncional) => {
        const that = this;
        if (window.stream) {
            //console.log("cerrando");
            window.stream.getTracks().forEach(track => {
                track.stop();
            });
        }

        const constraints = {
            audio: false,
            video: (deviceFuncional) ? {
                deviceId: { exact: deviceFuncional },
                width: { ideal: 640 },
                height: { ideal: 480 },
                advanced: [
                    { width: 1280, height: 720 },
                    { aspectRatio: 4 / 3 }
                ]
            } : {
                facingMode: "environment",
                width: { ideal: 640 },
                height: { ideal: 480 },
                advanced: [
                    { width: 1280, height: 720 },
                    { aspectRatio: 4 / 3 }
                ]
            }
        };

        //console.log("Reconfiguracion",constraints);
        that.localStream = navigator.mediaDevices.getUserMedia(constraints)
            .catch(
                (error) => {
                    if (error.name == "NotFoundError" || error.name == "DevicesNotFoundError") {
                        //required track is missing 
                        evento('Captura ID', 'User Media', { error: error.name, status: 'NO SE ENCONTRO DISPOSITIVO Y/O TRACK' }, true);
                    } else if (error.name == "NotReadableError" || error.name == "TrackStartError") {
                        //webcam or mic are already in use 
                        evento('Captura ID', 'User Media', { error: error.name, status: 'LOS DISPOSITVOS SOLICITADOS ESTÁN EN USO' }, true);
                    } else if (error.name == "OverconstrainedError" || error.name == "ConstraintNotSatisfiedError") {
                        //constraints can not be satisfied by avb. devices 
                        evento('Captura ID', 'User Media', { error: error.name, status: 'EL DISPOSITIVO NO PUEDE ALCANZAR LOS CONSTRAINTS' }, true);
                    } else if (error.name == "NotAllowedError" || error.name == "PermissionDeniedError") {
                        //permission denied in browser 
                        evento('Captura ID', 'User Media', { error: error.name, status: 'PERMISOS DENEGADOS' }, true);
                    } else if (error.name == "TypeError" || error.name == "TypeError") {
                        //empty constraints object 
                        evento('Captura ID', 'User Media', { error: error.name, status: 'CONSTRAINTS VACÍOS' }, true);
                    } else {
                        //other errors 
                        evento('Captura ID', 'User Media', { error: error.toString(), status: 'OTRO TIPO DE ERROR' }, true);
                    }
                    that.setState({ errorVideo: true })
                }
            );

        that.canvas = document.querySelector('canvas');
        that.width = 320;
        that.height = 0;
        that.video = document.querySelector('video');
        that.elemento = document.getElementById("cuadro");
        that.cuenta = document.getElementById("numeros");
        that.titulo = document.getElementById("head_shop");
        let component = that;
        that.localStream.then(function (mediaStream) {
            component.video.srcObject = mediaStream;
            component.video.onloadedmetadata = function (e) {
                component.video.play();
                if (mediaStream.getVideoTracks().length > 0) {
                    //console.log("disponibles");
                    component.setState({ streamStarted: true, errorVideo: false });
                } else {
                    //console.log("No disponibles");    
                    component.setState({ errorVideo: true })
                }
            };
            component.video.addEventListener('canplay', function (ev) {
                component.height = component.video.videoHeight / (component.video.videoWidth / component.width);
                component.canvas.setAttribute('width', component.video.videoWidth * 1.5);
                component.canvas.setAttribute('height', component.video.videoHeight * 1.5);
            }, false);
        }).catch(e => {
            console.trace(e);
            that.setState({ errorVideo: true })
        })

    }

    cancelarCapturaIdentificacion = () => {
        evento('Captura ID', 'Click', { description: 'CANCELAR' }, true);
        this.props.history.push("/preparacion_identificacion");
    }

    takeFotoFront = () => {
        //console.log("en foto");
        setTimeout(() => {
            //console.log("tomando foto");
            let contextCanvas = this.canvas.getContext('2d');
            let urlCreator = window.URL || window.webkitURL;
            contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
            const that = this;
            this.canvas.toBlob((blob) => {
                //obtenerOCR(uuid, imagen, side, isIOS, isMobileSafari)
                that.setState({ loading: false, previewId: true, imageUrl: urlCreator.createObjectURL(blob), capturing: false });
            }, 'image/png', 0.9);
        }, 1350);
    }

    takeFotoBack = () => {
        //console.log("en foto back");
        setTimeout(() => {
            //console.log("tomando foto back");
            let contextCanvas = this.canvas.getContext('2d');
            let urlCreator = window.URL || window.webkitURL;
            contextCanvas.drawImage(this.video, 0, 0, this.video.videoWidth * 1.5, this.video.videoHeight * 1.5);
            const that = this;
            this.canvas.toBlob((blob) => {
                //obtenerOCR(uuid, imagen, side, isIOS, isMobileSafari)
                that.setState({ loading: false, previewId: true, imageUrl: urlCreator.createObjectURL(blob), capturing: false });
            }, 'image/png', 0.9);
        }, 1350);
    }

    initRecord = (side) => {
        this.setState({ side: side, loading: true, capturing: true });
        let capturar = document.getElementById('capturar');
        capturar.classList.remove('main_bg_color');
        capturar.classList.add('gray_bg_color');
        let description = (side === 'front') ? 'CAPTURAR FRENTE' : 'CAPTURAR REVERSO';
        evento('Captura ID', 'Click', { description: description }, true);
        if (side === "front") {
            //this.enviarFotoFront()
            this.takeFotoFront()
        } else {
            //this.enviarFotoBack()
            this.takeFotoBack()
        }
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.localStream) {
            this.localStream.then(function (mediaStream) {
                if (mediaStream) mediaStream.getTracks().forEach(track => track.stop());
            });
            this.localStream = null;
        }
        window.removeEventListener("beforeunload", () => {//console.log("Se quito componente video");
        });
    }

    sendData = () => {
        var jsonObj = {};
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront"));
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack"));
        statusData("captura_id_ocr", jsonObj)
        //var res = JSON.stringify(jsonObj)
    }

    checkCamera = () => {
        setTimeout(() => {
            //console.log("Camera:"+this.state.streamStarted);
            if (!this.state.streamStarted) {
                this.setState({ errorVideo: true })
            }
        }, 3500);
    }

    enviarImagen = (blobImage) => {
        //console.log("Image", blobImage);
        //console.log("Side", this.state.side);
        let tipo = '';
        let informacion = {};
        const that = this;
        that.setState({ previewId: false, proccessing: true, capturing: true });
        if (that.state.side === "front") {
            obtenerOCR(that.state.uuid, blobImage, "front", isIOS, isMobileSafari)
                .then((response) => {
                    if (response.status === 200) {
                        informacion.status = response.status;
                        informacion.payload = response.data.payload;
                        informacion.side = that.state.side;
                        //console.log("200","nuevoOCR");
                        let fechaFinal = response.data.payload.ocrData.fechaNacimiento
                        let fechaOcr = '';
                        if (fechaFinal) {
                            if (fechaFinal.length > 6 && fechaFinal.length < 10) {
                                let añoActualInt = new Date().getFullYear().toString().substr(-2);
                                let añoFechaOcr = fechaFinal.substr(-2);
                                let añoFechaOcrInt = fechaFinal.substr(-2);
                                let fechaSinAño = fechaFinal.substr(0, 6);
                                if (añoFechaOcrInt >= añoActualInt) {
                                    fechaOcr = fechaSinAño + '19' + añoFechaOcr;
                                } else {
                                    let mayoriaDeEdad = añoActualInt - 18;
                                    if (añoFechaOcrInt <= mayoriaDeEdad) {
                                        fechaOcr = fechaSinAño + '20' + añoFechaOcr;
                                    } else {
                                        fechaOcr = fechaSinAño + '20' + añoFechaOcr;
                                    }
                                }
                            } else if (fechaFinal.length < 6) {
                                fechaOcr = ""
                            } else {
                                fechaOcr = fechaFinal
                            }
                        }
                        that.setState({
                            dataOCRFront: {
                                nombreCompleto: response.data.payload.ocrData.nombreCompleto,
                                curp: response.data.payload.ocrData.curp,
                                dni: response.data.payload.ocrData.dni,
                                claveElector: response.data.payload.ocrData.claveElector,
                                estado: response.data.payload.ocrData.estado,
                                municipio: response.data.payload.ocrData.municipio,
                                seccion: response.data.payload.ocrData.ineSeccion,
                                colonia: response.data.payload.ocrData.colonia,
                                calle: response.data.payload.ocrData.calle,
                                cp: response.data.payload.ocrData.cp,
                                numero: response.data.payload.ocrData.numero,
                                anioRegistro: response.data.payload.ocrData.anioRegistro,
                                numeroRegistro: response.data.payload.ocrData.numeroRegistro,
                                ineRegistro: response.data.payload.ocrData.ineRegistro,
                                emision: response.data.payload.ocrData.ineEmision,
                                vigencia: response.data.payload.ocrData.ineVigencia,
                                fechaNacimiento: fechaOcr,
                                sexo: response.data.payload.ocrData.sexo,
                                apellidoPaterno: response.data.payload.ocrData.apellidoPaterno,
                                apellidoMaterno: response.data.payload.ocrData.apellidoMaterno,
                                nombres: response.data.payload.ocrData.nombres,
                                identificacionElectoral: response.data.payload.ocrData.identificacionElectoral,
                                documentoNacionalPeru: response.data.payload.ocrData.documentoNacionalPeru,
                                identificacionMigratoria: response.data.payload.ocrData.identificacionMigratoria,
                                identificacionPasaporte: response.data.payload.ocrData.identificacionPasaporte,
                                modeloIdentificacion: response.data.payload.ocrData.modeloIdentificacion,
                                documentoPermanente: response.data.payload.ocrData.documentoPermanente,
                            }
                        })
                        localStorage.setItem("ocrFront", JSON.stringify({
                            nombreCompleto: response.data.payload.ocrData.nombreCompleto,
                            curp: response.data.payload.ocrData.curp,
                            dni: response.data.payload.ocrData.dni,
                            claveElector: response.data.payload.ocrData.claveElector,
                            estado: response.data.payload.ocrData.estado,
                            municipio: response.data.payload.ocrData.municipio,
                            seccion: response.data.payload.ocrData.ineSeccion,
                            colonia: response.data.payload.ocrData.colonia,
                            calle: response.data.payload.ocrData.calle,
                            cp: response.data.payload.ocrData.cp,
                            numero: response.data.payload.ocrData.numero,
                            ineRegistro: response.data.payload.ocrData.ineRegistro,
                            anioRegistro: response.data.payload.ocrData.anioRegistro,
                            numeroRegistro: response.data.payload.ocrData.numeroRegistro,
                            emision: response.data.payload.ocrData.ineEmision,
                            vigencia: response.data.payload.ocrData.ineVigencia,
                            fechaNacimiento: fechaOcr,
                            sexo: response.data.payload.ocrData.sexo,
                            apellidoPaterno: response.data.payload.ocrData.apellidoPaterno,
                            apellidoMaterno: response.data.payload.ocrData.apellidoMaterno,
                            nombres: response.data.payload.ocrData.nombres,
                            identificacionElectoral: response.data.payload.ocrData.identificacionElectoral,
                            documentoNacionalPeru: response.data.payload.ocrData.documentoNacionalPeru,
                            identificacionMigratoria: response.data.payload.ocrData.identificacionMigratoria,
                            identificacionPasaporte: response.data.payload.ocrData.identificacionPasaporte,
                            modeloIdentificacion: response.data.payload.ocrData.modeloIdentificacion,
                            documentoPermanente: response.data.payload.ocrData.documentoPermanente,
                        }))
                        //console.log("guarde");
                        var tipo = ""
                        if (that.state.dataOCRFront.identificacionElectoral) {
                            tipo = "INE";
                        }
                        else if (that.state.dataOCRFront.identificacionPasaporte) {
                            tipo = "PASAPORTE";
                            that.setState({ isPassport: true });
                        }
                        else if (that.state.dataOCRFront.documentoNacionalPeru) {
                            tipo = "DNI_PERU";
                        }
                        else if (that.state.dataOCRFront.identificacionMigratoria) {
                            tipo = "FMM";
                        }
                        else {
                            tipo = "INE";
                        }
                        var jsonObj = { "type_id": tipo };
                        statusData("captura_id", jsonObj)
                        //console.log(("tipo",tipo));
                        if (tipo === "PASAPORTE") {
                            that.setState({ loading: false, proccessing: false, hasTextBack: true, capturing: false })
                            that.sendData()
                            setTimeout(() => {
                                that.props.history.push({
                                    pathname: '/resultados_identificacion',
                                    state: { passport: false }
                                })
                            }, 300);
                        } else {
                            let dataOCRFrontLS = localStorage.getItem("ocrFront")
                            const ocrFront = JSON.parse(dataOCRFrontLS)
                            //console.log(ocrFront);
                            if (ocrFront.nombreCompleto && (ocrFront.curp || ocrFront.dni)) {
                                if (ocrFront.dni) {
                                    if (ocrFront.dni.length < 3 || ocrFront.nombreCompleto.length < 10) {
                                        if (that.state.intentsData < that.state.intentos) {
                                            localStorage.setItem("intentsData", "OCR");
                                            that.setState({ loading: false, proccessing: false, side: "back", hasTextFront: true, intentsFront: 0, intentsData: 0, capturing: false })
                                        } else {
                                            that.setState({ loading: false, proccessing: false, intentsData: that.state.intentsData + 1, errorF: "Datos no cumplen con formato", hasResponse: true, capturing: false })
                                            that.statusR();
                                        }
                                    } else {
                                        that.setState({ loading: false, proccessing: false, side: "back", hasTextFront: true, intentsFront: 0, intentsData: 0, capturing: false })
                                    }
                                } else {
                                    if (ocrFront.curp.length < 16 || ocrFront.nombreCompleto.length < 10) {
                                        if (that.state.intentsData < that.state.intentos) {
                                            localStorage.setItem("intentsData", "OCR");
                                            that.setState({ loading: false, proccessing: false, side: "back", hasTextFront: true, intentsFront: 0, intentsData: 0, capturing: false })
                                        } else {
                                            that.setState({ loading: false, proccessing: false, intentsData: that.state.intentsData + 1, errorF: "Datos no cumplen con formato", hasResponse: true, capturing: false })
                                            that.statusR();
                                        }
                                    } else {
                                        that.setState({ loading: false, proccessing: false, side: "back", hasTextFront: true, intentsFront: 0, intentsData: 0, capturing: false })
                                    }
                                }
                            } else {
                                if (that.state.intentsData < that.state.intentos) {
                                    localStorage.setItem("intentsData", "OCR");
                                    that.setState({ loading: false, proccessing: false, side: "back", hasTextFront: true, intentsFront: 0, intentsData: 0, capturing: false })
                                } else {
                                    that.setState({ loading: false, proccessing: false, intentsData: that.state.intentsData + 1, errorF: "Datos incompletos", hasResponse: true, capturing: false })
                                    that.statusR();
                                }
                                ////console.log("intentsData",intentsData);
                            }
                        }
                        if (that.state.intentsFront === Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "99")) && that.state.hasFace) {
                            if (tipo === "PASAPORTE") {
                                that.setState({ loading: false, proccessing: false, hasTextBack: true, capturing: false })
                                that.sendData()
                                setTimeout(() => {
                                    that.props.history.push({
                                        pathname: '/resultados_identificacion',
                                        state: { passport: false }
                                    })
                                }, 300);
                            } else {
                                that.setState({ loading: false, proccessing: false, side: "back", capturing: false })
                            }
                        }

                        that.setState({ loading: false, proccessing: false, hasResponse: true, capturing: false });
                        evento('Captura ID', 'Success', informacion, true);
                    } else {
                        //console.log("otreo");
                        that.setState({ loading: false, proccessing: false, intentsFront: that.state.intentsFront + 1, errorF: response.data.message, hasResponse: true, capturing: false });
                        informacion.status = response.status;
                        informacion.payload = response.data.payload;
                        informacion.side = that.state.side;
                        if (that.state.intentsFront === that.state.intentos)
                            that.finalizarFlujo(informacion);
                        else
                            that.statusR();
                        that.setState({ errorMsg: true })
                    }
                })
                .catch((error) => {
                    that.setState({ errorMessage: "Por favor ubica tu identificación en el área marcada para continuar.", intentsFront: that.state.intentsFront + 1, errorCapture: true, errorF: "Error en la captura de identificación", token: null, loading: false, proccessing: false, hasResponse: true, capturing: false });
                    if (error.response) {
                        let { data: { message_client }, status } = error.response
                        informacion.mensaje = message_client;
                        informacion.status = status;
                        tipo = 'Error';
                    } else {
                        informacion.error = error.toString();
                        tipo = 'Exception';
                    }
                    informacion.side = that.state.side;
                    evento('Captura ID', tipo, informacion, false);
                    if (that.state.intentsFront === that.state.intentos)
                        that.finalizarFlujo(informacion);
                    else
                        that.statusR();
                })
        } else {
            obtenerOCR(that.state.uuid, blobImage, "back", isIOS, isMobileSafari)
                .then((response) => {
                    if (response.status === 200) {
                        informacion.status = response.status;
                        informacion.payload = response.data.payload;
                        informacion.side = that.state.side;
                        //console.log("200-back",response.data);
                        that.setState({
                            dataOCRBack: {
                                cadena: response.data.payload.ocrData.cadena,
                                ineVigencia: response.data.payload.ocrData.ineVigencia,
                                cicMRZ: response.data.payload.ocrData.cicMRZ,
                                ciudadanoMRZ: response.data.payload.ocrData.ciudadanoMRZ,
                                nombreCompletoMRZ: response.data.payload.ocrData.nombreCompletoMRZ,
                                seccionMRZ: response.data.payload.ocrData.seccionMRZ

                            }
                        })
                        localStorage.setItem("ocrBack", JSON.stringify({
                            cadena: response.data.payload.ocrData.cadena,
                            ineVigencia: response.data.payload.ocrData.ineVigencia,
                            cicMRZ: response.data.payload.ocrData.cicMRZ,
                            ciudadanoMRZ: response.data.payload.ocrData.ciudadanoMRZ,
                            nombreCompletoMRZ: response.data.payload.ocrData.nombreCompletoMRZ,
                            seccionMRZ: response.data.payload.ocrData.seccionMRZ
                        }))
                        //console.log("guarde");
                        that.setState({ loading: false, proccessing: false, hasTextBack: true, capturing: false })
                        that.sendData()
                        setTimeout(() => {
                            that.props.history.push({
                                pathname: '/resultados_identificacion',
                                state: { passport: false }
                            })
                        }, 300);
                        evento('Captura ID', 'Success', informacion, true);
                    } else {
                        that.setState({ loading: false, proccessing: false, intentsBack: that.state.intentsBack + 1, errorF: "Error en la captura", hasResponse: true, capturing: false });
                        that.statusR();
                        if (that.state.intentsBack === Number(obtenerValorConfig(that.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "99"))) {
                            that.sendData()
                            setTimeout(() => {
                                that.props.history.push({
                                    pathname: '/resultados_identificacion',
                                    state: { passport: false }
                                })
                            }, 300);
                        }
                    }
                })
                .catch((error) => {
                    that.setState({ errorMessage: "Por favor coloca la parte de atras de tu identificación.", errorCapture: true, errorF: "Error en la captura de identificación", token: null, proccessing: false, loading: false, hasResponse: true, capturing: false })
                    if (error.response) {
                        let { data: { message_client }, status } = error.response
                        informacion.mensaje = message_client;
                        informacion.status = status;
                        tipo = 'Error';
                    } else {
                        informacion.error = error.toString();
                        tipo = 'Exception';
                    }
                    informacion.side = that.state.side;
                    evento('Captura ID', tipo, informacion, false);
                    if (that.state.intentsFront === that.state.intentos)
                        that.finalizarFlujo(informacion);
                    else
                        that.statusR();
                })
        }
    }

    permisoCamara = () => {
        let component = this;
        var deviceFuncional = "";
        navigator.mediaDevices.enumerateDevices().then(function (devices) {
            for (var i = 0; i < devices.length; i++) {
                var device = devices[i];
                if (device.kind === 'videoinput') {
                    //console.log(device.label)
                    if ((device.label).includes("back")) {
                        if ((device.label).includes("0")) {
                            deviceFuncional = device.deviceId;
                            //console.log("deviceFuncional1", deviceFuncional);
                        }
                    }
                }
            }
            component.start(deviceFuncional);
        });
    }

    statusR = () => {
        statusReintento("Reintento Captura ID", this.state.errorF, "RETRY");
    }

    finalizarFlujo = async (response) => {
        this.setState({ errorCapture: false });
        localStorage.setItem("bloqueo", "true");
        let newDate = new Date().getTime();
        let acceptDta = new Date();
        acceptDta.setTime(newDate + (60 * 60 * 1000))
        localStorage.setItem("time", acceptDta.getTime())
        let res = this.convertdataJSON();
        let event_t = await JSON.stringify(res);
        var json_final = {};
        let status = "FAIL"
        json_final = {
            "status": status,
            "step": "finished",
            "description": event_t,
            'response': response,
            "finished": true
        }
        let event = JSON.stringify(json_final);
        await flowIncompleted({ 'step': 'captura_id', 'event': event }, 'red');
        // await flowIncompleted({ 'step': 'finalizado', 'event': event }, 'red');
        // localStorage.setItem("completado", "true");
        // if (window.opener) {
        //     setTimeout(() => {
        //         window.close();
        //     }, 300);
        // }
    }

    convertdataJSON = () => {
        let jsonObj = {};
        jsonObj.uuidUser = localStorage.getItem("uuidUser") || '';
        jsonObj.uuidTrx = localStorage.getItem("uuidTrx") || '';
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
        jsonObj.data_user = JSON.parse(localStorage.getItem("data_user")) || {};
        jsonObj.links = JSON.parse(localStorage.getItem('links')) || {};
        return jsonObj;
    }

    render() {
        const { isLandscape } = this.props;
        return (
            <div>
                {(this.state.isStart && !this.state.showHelp) ?
                    <Fragment>
                        <div className="nu_gradient hollo_bx show_id_hold">

                            <Header ruta="/identificacion" show={() => this.setState({ showHelp: true })} />

                            <div className="main_text_container">
                                <h1 className="fake_over">Tómale una foto al frente de tu identificación</h1>
                                <br />
                                <p className="izquierdaText">Colócala sobre una <b>superficie obscura</b> y <b>encuádrala</b> con la guía.
                                    <br />
                                    <br />
                                    ¡Sigue las <b>instrucciones</b>!
                                </p>
                                <br />
                                <div className="row id_type_bx">
                                    <div className="col-12">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <img src="images/wl/id_check.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="div_bottom" id="div-buttons-calibrate">
                                <div className="action_buttons">
                                    <button type="button" onClick={(e) => {
                                        this.permisoCamara();
                                        this.setState({ isStart: false, showIdOverlay: (this.state.desktop) ? true : false });
                                        let localVideo = document.getElementById('video_wrt');
                                        localVideo.classList.remove("blur_video");
                                        evento('Captura ID', 'Click', { description: 'CONTINUAR' }, true);
                                    }} className="btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__fadeIn animate__delay-1s">CONTINUAR</button>
                                    <button onClick={e => this.cancelarCapturaIdentificacion()} className="btn btn-primary forcewidth100 main_color animate__animated animate__fadeIn animate__delay-1s">CANCELAR</button>

                                </div>
                            </div>
                        </div>
                    </Fragment> : ("")}

                <div className="module_container overflow_hddn desk_id_capture" style={{ position: 'fixed' }}>
                    <div className="camera_capture_frame">
                        <video id="video_wrt" playsInline></video>
                        <canvas id="canvas_wrt_environment" hidden></canvas>
                    </div>
                    <div className="module_gradient_overlay"></div>
                    <div className="module_title animate__animated animate__slideInDown">
                        <p>Coloca tu identificación  de <b>{this.state.side === "front" ? "frente" : "reverso"}</b></p>
                    </div>

                    {(this.state.previewId) ? ("") : (isMobile) ?
                        <div>
                            <img className="ine_mask_cel animate__animated animate__fadeIn animate__delay-2s" src={process.env.PUBLIC_URL + '/images/id_mask_capture_a.svg'} alt="" />
                            <div className="module_icon_container  animate__animated animate__fadeIn animate__delay-3s">
                                {(this.state.side === "front") ?
                                    <img src='images/wl/id_front.svg' alt="" />
                                    :
                                    <img src='images/wl/id_back.svg' alt="" />
                                }
                            </div>
                        </div>

                        :
                        <div>
                            <img className="ine_mask id_mask animate__animated animate__fadeIn animate__delay-2s" src={process.env.PUBLIC_URL + '/images/id_mask.svg'} alt="" />
                            <div className="module_icon_container  animate__animated animate__fadeIn animate__delay-3s">
                                {(this.state.side === "front") ?
                                    <img src='images/wl/id_front.svg' alt="" />
                                    :
                                    <img src='images/wl/id_back.svg' alt="" />
                                }
                            </div>
                        </div>
                    }
                </div>
                {(this.state.previewId ? ("") : <div className="alert_instruction animate__animated animate__fadeIn animate__delay-1s alert_light">
                    <img height="24" src={process.env.PUBLIC_URL + 'images/evita_reflejos_warn.png'} alt="" />
                </div>)}

                {this.state.intentsFront <= Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "99")) && !this.state.previewId && !this.state.showHelp && (<div className="module_buttons animate__animated animate__fadeIn animate__delay-3s" style={{ position: 'fixed' }}>
                    {this.state.side === "front" ? <button id="capturar" type="button" onClick={() => this.initRecord("front")} className={["btn btn-raised btn-primary forcewidth100 ", (this.state.capturing ? "gray_bg_color" : "main_bg_color"), (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} disabled={this.state.proccessing || this.state.loading}>{(this.state.proccessing || this.state.loading ? "PROCESANDO..." : "CAPTURAR FRENTE")}</button> : <button id="capturar" type="button" onClick={() => this.initRecord("back")} className={["btn btn-raised btn-primary forcewidth100 ", (this.state.capturing ? "gray_bg_color" : "main_bg_color"), (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} disabled={this.state.proccessing || this.state.loading}>{(this.state.proccessing || this.state.loading ? "PROCESANDO..." : "CAPTURAR REVERSO")}</button>}
                    <button type="button" onClick={() => this.cancelarCapturaIdentificacion()} className={["btn btn-raised btn-primary forcewidth100 cancel_btn main_color", (isMobile) ? "" : "desk_id_capture_btn"].join(" ")} >CANCELAR</button>
                </div>)}
                {((this.state.desktop && this.state.showIdOverlay) && <div className="id_hold show_id_hold">
                    <div className="center_info_bx">
                        <img src="images/id_hold_icon.png" height="200" alt="" />
                        <p>Toma tu identificación solo con dos dedos <br /> sin obstruir tu foto u otra información<br />
                            <small>Sugerimos tomarla de la esquina superior izquierda</small>
                        </p>
                        <br />
                        <button type="button" className="btn btn-raised btn-primary forcewidth100 main_bg_color" onClick={() => { this.permisoCamara(); this.setState({ showIdOverlay: false }) }}>ENTENDIDO EMPEZAR</button>
                    </div>
                </div>)}

                {this.state.intentsFront >= Number(obtenerValorConfig(this.state.dataOtorgante, "INTENTOS_ID_FRONTAL", "99")) && (<div className="err_finale  animate__animated animate__slideInUp">
                    <div className="center_checks">
                        <h5>Proceso no exitoso </h5>
                        <p>Haz superado el número <b>máximo</b> de intentos para este proceso inténtalo nuevamente <b>más tarde</b>.</p>
                        <p>Si deseas mayor información no dudes en contactarnos con el <b>ID</b> de proceso <b>{(this.state.uuidTrx).split("-")[0]}</b> </p>
                        <br />
                        {
                            (this.state.pathname === "/Cw2qjkHFRvpLPryvUWXDcOPMEgHIYI9X") &&
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <p><b>Llámanos al:</b></p>
                                        <p><a className="help_phone" href={"tel:" + obtenerValorConfig(this.state.dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(this.state.dataOtorgante, 'TEL_CONTACTO_PRINCIPAL')}</a></p>
                                    </div>
                                    <div className="col">
                                        <p><b>Escríbenos a:</b></p>
                                        <p><a className="help_mail" href={"mailto:" + obtenerValorConfig(this.state.dataOtorgante, 'EMAIL_CONTACTO_PRINCIPAL')}>{obtenerValorConfig(this.state.dataOtorgante, "EMAIL_CONTACTO_PRINCIPAL")}</a></p>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="action_buttons noscroll_screen">
                            {
                                <Link to={"/" + this.state.apikey} className="btn btn-raised btn-primary forcewidth100 main_bg_color">ENTENDIDO</Link>
                            }
                        </div>
                    </div>
                </div>)}

                {(this.state.proccessing) && <LoaderOCR side={this.state.side} show={() => this.setState({ showHelp: true })} />}

                {((this.state.loading) && <div className="sprite_stay_a animado"></div>)}
                {(isLandscape) && (
                    <div className="rotate_device">
                        <div className="center_info_bx">
                            <img src="images/rotate_device.svg" height="100" alt="" />
                            <p>Por favor usa tu dispositivo en vertical<br />
                                <small>Gira tu dispositivo para continuar</small>
                            </p>
                        </div>
                    </div>
                )}
                {(this.state.intentsData === 1) || this.state.errorCapture &&
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animate__animated animate__slideInDown">No se pudo leer correctamente tus datos, por favor vuelve a intentar</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ intentsData: 2, errorCapture: false })
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>}
                {(this.state.errorMsg) &&
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animate__animated animate__slideInDown">Se ha detectado un bloqueo de red, verifica tu conexión de internet</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ errorMsg: false })
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>}
                {(this.state.errorVideo) &&
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animate__animated animate__slideInDown">Hemos detectado que la <b>cámara de tu dispositivo</b> está siendo usada por otra web en <b>alguna pestaña</b>, por favor asegúrate de <b>cerrar las pestañas</b> abiertas e inténtalo nuevamente.</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ errorVideo: false })
                                this.props.history.push("/preparacion_identificacion");
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>}
                {this.state.previewId && <CropPhoto tipo="identificacion" imageUrl={this.state.imageUrl} enviarImagen={blobImage => this.enviarImagen(blobImage)} reload={() => { this.setState({ previewId: false, capturing: false }); evento('Captura ID', 'Click', { description: 'CAPTURAR DE NUEVO' }, true); }} side={this.state.side} />}
                {this.state.showHelp && <Ayuda hide={() => this.setState({ showHelp: false })} />}
            </div>
        );
    }


}
CameraPhotoIOS = withOrientationChange(CameraPhotoIOS)
export default withRouter(CameraPhotoIOS)