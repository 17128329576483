import {
    actualizarEstado, enviarEvento, enviarHologramaIdentificacion, enviarRenapo, obtenerResultadoRenapo, getDocuments, enviarIne, obtenerResultadoINE,
    enviarZipAmarillos
} from '../services/api.js';
import {
    v4 as uuidv4
} from 'uuid';
import {
    obtenerValorConfig,
    obtenerValorUser
} from './configStyle';
import moment from 'moment';
import history from '../services/history';

let objetoFinal = {};

export const agregarInfo = (dataUser, elemento) => {
    let dataUserLS = JSON.parse(localStorage.getItem("data_user"))
    const elementoEncontrado = dataUserLS.findIndex(function (item) {
        return item.description === elemento.description
    })
    ////console.log("elementoEncontrado" + elementoEncontrado + " item:"+elemento);
    if (elementoEncontrado !== -1) {
        dataUserLS[elementoEncontrado] = elemento
    } else {
        dataUserLS.push(elemento)
    }
    localStorage.setItem("data_user", JSON.stringify(dataUserLS))
}


export const hayExcepcion = () => {
    const uuidExcepcion = ["Wco5CTXw7e0JoKR9p7TB9fqOjpUS7iuu", "VCVi90WBhqtRPWboXtfpHBQlLPO00jd4", "MQZq6jzleILkHbQhGULIdwPhn5Jv19HD"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    return respuesta
}

export const regresarJson = () => {
    const uuidExcepcion = ["iQNTQ2qoBLfAy0iH4V2OUM5l36BIUoCt"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    return respuesta
}

export const esEditable = () => {
    //lso campos son editables para CDC y otorgantes
    const uuidExcepcion = ["0zmV8ljIWoFfGsaieC4vuEPwpVKYYrAP", "MTMba54LICmL2Boez3KuFdVL1vD1boKk", "7a531a75-e0cf-4158-bd99-86b733ef22ad", "94b502fd-5546-44e1-b12f-2122d410cea0"];
    let respuesta = false;
    uuidExcepcion.forEach(function (index) {
        if (index === localStorage.getItem("apikeyOtorgante")) {
            respuesta = true
        }
    });
    //console.log("respuesta", respuesta);
    return respuesta
}

export const initStatus = (nombreOtorgante) => {
    //console.log("entre a initStatus");
    updateInitStatus(nombreOtorgante);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: "inicio_page",
            result: "OK",
            //data: { transaccion: localStorage.getItem("uuidTrx")}
        }), new URL(window.location).searchParams.get("referer"))
        //console.log("hay referer");
        window.opener.postMessage(JSON.stringify({
            step: "inicio",
            result: "OK",
            data: {
                transaccion: localStorage.getItem("uuidUser")
            }
        }), new URL(window.location).searchParams.get("referer"))
        localStorage.setItem("externalReference", new URL(window.location).searchParams.get("referer"))
        localStorage.setItem("externalJWT", new URL(window.location).searchParams.get("jwt"))
    }
    if (new URL(window.location).searchParams.get("referer")) {
        localStorage.setItem("externalReference", new URL(window.location).searchParams.get("referer"))
    }
}

const updateInitStatus = async (nombreOtorgante) => {
    if (localStorage.getItem("completado") === "false") {
        let tipo = '';
        let informacion = {};
        let trx_id = localStorage.getItem("uuidTrx");
        if (!trx_id) {
            //console.log("se creo nuevo trx");
            trx_id = uuidv4();
            localStorage.setItem("uuidTrx", trx_id);
        }
        let json = {
            "status": "OK",
            "step": "inicio"
        }
        let company_id = localStorage.getItem("apikeyOtorgante");
        let client_id = localStorage.getItem("uuidUser");
        let event = JSON.stringify(json);
        let objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'step': "inicio_page",
            'event': event,
            'origin': "celd-cl",
            'nombreOtorgante': nombreOtorgante
        }
        //console.log("uuidtx:", localStorage.getItem("uuidTrx"));
        actualizarEstado(objetoStatus).then(({ status }) => {
            if (status === 200) {
                //console.log("Se actualizo status INIT");
                informacion.status = status;
                informacion.dataLifeCycle = objetoStatus;
                tipo = 'Update Cycle';
                evento('Inicio', tipo, informacion, true);
            }
        }).catch((error) => {
            //console.log("No se pudo actualizar init Status. "+e);
            //setLoading(false)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error Update Cycle';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception Update Cycle';
            }
            evento('Inicio', tipo, informacion, false);
        })
    } else {
        return
    }
}

export const status = (paso, updatepaso) => {
    let json = {
        "status": "OK",
        "step": updatepaso
    }
    let event_t = JSON.stringify(json);

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    updateStatus(objetoStatus);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            result: "OK"
        }), localStorage.getItem("externalReference"))
    }
}

export const statusData = (paso, datos) => {
    var json = {};
    json = {
        "status": "OK",
        "step": paso,
        "description": datos
    }
    let event_t = JSON.stringify(json)

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    updateStatus(objetoStatus);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            result: "OK",
            data: datos
        }), localStorage.getItem("externalReference"))
    }
}

export const statusError = (paso, datos, tipo) => {
    //console.log("entre a status cancelar");
    var json = {};
    let status = "INCOMPLETED"
    if (tipo !== "cancelado") {
        status = "FAIL"
    }
    json = {
        "status": status,
        "step": paso,
        "description": datos
    }
    let event_t = JSON.stringify(json);

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }
    updateStatus(objetoStatus);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            status: status,
            type: "PROCESS",
            error_text: datos,
        }), localStorage.getItem("externalReference"))
        if (tipo === "cancelado")
            window.close()
    }
}

const updateStatus = async (objetoInformacion) => {
    if (localStorage.getItem("completado") === "false") {
        let tipo = '';
        let informacion = {};
        let dataEvent = null;
        let trx_id = localStorage.getItem("uuidTrx");
        let company_id = localStorage.getItem("apikeyOtorgante");
        let client_id = localStorage.getItem("uuidUser");
        const dataOtorgante = (JSON.parse(localStorage.getItem("dataOtorgante")));
        const dataUser = (JSON.parse(localStorage.getItem("data_user")));
        const bandera = localStorage.getItem("flag");

        let telefono = localStorage.getItem("movil") ? localStorage.getItem("movil") : '';
        let correo = localStorage.getItem("correoElectronico") ? localStorage.getItem("correoElectronico") : '';
        let fullName = dataUser ? obtenerValorUser(dataUser, "nombreCompleto", "") : '';
        let flag = bandera ? bandera : '';

        let objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'origin': "celd-cl",
            'nombreOtorgante': obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""),
            'nombreCompleto': fullName,
            'telefono': telefono,
            'correoElectronico': correo,
            'bandera': flag
        }

        objetoFinal = {
            ...objetoStatus,
            ...objetoInformacion
        }

        dataEvent = JSON.parse(objetoInformacion.event)

        actualizarEstado(objetoFinal).then(({ status }) => {
            if (status === 200) {
                //console.log("Se actualizo status INIT");
                informacion.status = dataEvent.status;
                informacion.dataLifeCycle = objetoFinal;
                tipo = 'Update Cycle';
                evento(dataEvent.step, tipo, informacion, true);
            }
        }).catch((error) => {
            //console.log("No se pudo actualizar init Status. "+e);
            //setLoading(false)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error Update Cycle';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception Update Cycle';
            }
            evento(dataEvent.step, tipo, informacion, false);
        })
    } else {
        return
    }
}

/* Validaciones */

/* Validaciones */

export const hayLetras = (input) => {
    let result = true
    var expreg = /[a-z]/gi;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    return result
}

export const esNumLetras = (input) => {
    let result = true
    var expreg = /^[A-Za-z0-9]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esNumLetras",result);
    return result
}

export const esSoloLetras = (input) => {
    let result = true
    let expreg = /^[A-Za-z-zÀ-ú\s]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esSoloLetras",result);
    return result
}

export const esNumeros = (input) => {
    let result = true
    var expreg = /^[0-9]+$/g;
    //var result = input.match(expreg);
    if (!input.match(expreg))
        result = false
    //console.log("esnum",result);
    return result
}

export const statusReintento = (paso, datos, tipo) => {
    var json = {};
    let status = tipo
    json = {
        "status": status,
        "step": paso,
        "description": datos
    }

    let event_t = JSON.stringify(json)

    let objetoStatus = {
        'step': paso,
        'event': event_t
    }

    updateStatus(objetoStatus);
}

export const sendData = (objetoStatus) => {
    updateStatus(objetoStatus);
}

export const calculateX = (innerWidth, percent, tipo) => {
    //console.log("Device Ratio:",ratio);

    if (tipo === "string") {
        return (Math.ceil(innerWidth * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerWidth * percent / 100));
    }
};

export const calculateY = (innerHeight, percent, tipo) => {
    //console.log("Device Ratio:",ratio);

    if (tipo === "string") {
        return (Math.ceil(innerHeight * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerHeight * percent / 100));
    }
};

export const calculateWidth = (innerWidth, percent, tipo) => {
    //console.log("Device Ratio:",ratio);
    if (tipo === "string") {
        return (Math.ceil(innerWidth * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerWidth * percent / 100));
    }
};

export const calculateHeight = (innerHeight, percent, tipo) => {
    //console.log("Device Ratio:",ratio);

    if (tipo === "string") {
        return (Math.ceil(innerHeight * percent / 100)).toString() + "px";
    } else {
        return (Math.ceil(innerHeight * percent / 100));
    }
};

export const formatoFechaOcr = (fecha) => {
    let formatosP = ['DD/MM/YYYY', 'DD/MM/YY']
    let nacimientoC = fecha
    if (fecha.includes(' '))
        nacimientoC = fecha.replaceAll(' ', '/')
    if (moment(nacimientoC, formatosP, true).isValid())
        return nacimientoC;
    else {
        return "";
    }
}

export const formatoFechaCuatroDigitos = (fecha) => {
    let añoActualInt = new Date().getFullYear().toString().substr(-2);
    let añoFechaOcr = fecha.substr(-2);
    let añoFechaOcrInt = fecha.substr(-2);
    let fechaSinAño = fecha.substr(0, 6);
    let fechaOcr = '';
    if (añoFechaOcrInt >= añoActualInt) {
        console.log("entre 19");
        fechaOcr = fechaSinAño + '19' + añoFechaOcr;
    } else {
        let mayoriaDeEdad = añoActualInt - 18;
        if (añoFechaOcrInt <= mayoriaDeEdad) {
            console.log("entre 20");
            fechaOcr = fechaSinAño + '20' + añoFechaOcr;
        } else {
            fechaOcr = fechaSinAño + '20' + añoFechaOcr;
        }

    }
    return fechaOcr;
}

export const validarValoresFormulario = (valores) => {
    let validacion = {
        errores: {},
        correcto: true
    };
    Object.entries(valores).forEach(([key, value]) => {
        let campoValidado = validarCampo(value, key);
        if (!campoValidado.correcto) {
            validacion.errores[key] = campoValidado.errores[key];
            validacion.correcto = false;
            return validacion;
        }
    });

    return validacion;
};

export const validarCampo = (valor, campo) => {
    let validacion = {
        errores: {},
        correcto: false
    };
    let formatosFecha = ['DD/MM/YYYY'];
    switch (campo) {
        case "nombreCompleto":
            if (!valor.trim()) {
                validacion.errores.nombreCompleto = 'Debes ingresar un valor válido';
            } else if (valor.length < 10 || !esSoloLetras(valor)) {
                validacion.errores.nombreCompleto = 'Nombre no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "nombres":
            if (!valor.trim()) {
                validacion.errores.nombres = 'Debes ingresar un valor válido';
            } else if (!esSoloLetras(valor)) {
                validacion.errores.nombres = 'Nombre no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "apellidoPaterno":
            if (!valor.trim()) {
                validacion.errores.apellidoPaterno = 'Debes ingresar un valor válido';
            } else if (!esSoloLetras(valor)) {
                validacion.errores.apellidoPaterno = 'Apellido paterno no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "apellidoMaterno":
            if (!valor.trim()) {
                validacion.correcto = true;
            } else if (!esSoloLetras(valor)) {
                validacion.errores.apellidoMaterno = 'Apellido materno no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "fechaNacimiento":
            if (!valor.trim()) {
                validacion.errores.fechaNacimiento = 'Debes ingresar un valor válido';
            } else if (moment(valor, formatosFecha, true).isValid()) {
                validacion.correcto = true;
            } else {
                validacion.errores.fechaNacimiento = 'Revisa el formato dd/mm/aaaa';
            }
            break;
        case "clave":
            if (!valor.trim()) {
                validacion.errores.clave = 'Debes ingresar un valor válido';
            } else if (!esNumLetras(valor) || valor.length < 18 || valor.length > 18) {
                validacion.errores.clave = 'Clave de elector no válida';
            } else {
                validacion.correcto = true;
            }
            break;
        case "fechaRegistro":
            if (!valor.trim()) {
                validacion.errores.fechaRegistro = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length !== 4) {
                validacion.errores.fechaRegistro = 'Revisa que el año sea 4 dígitos';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroEmision":
            if (!valor.trim()) {
                validacion.errores.numeroEmision = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length !== 2) {
                validacion.errores.numeroEmision = 'Formato de Emisión no válido, 2 dígitos';
            } else {
                validacion.correcto = true;
            }
            break;
        case "cic":
            if (!valor.trim() || valor === "") {
                validacion.errores.cic = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length < 8 || valor.length > 13) {
                validacion.errores.cic = 'Formato de CIC/OCR no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "ocr":
            if (!valor.trim() || valor === "") {
                validacion.errores.ocr = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor) || valor.length < 12 || valor.length > 13) {
                validacion.errores.ocr = 'Formato de OCR no válido';
            } else {
                validacion.correcto = true;
            }
            /*
            let dataUser = JSON.parse(localStorage.getItem("ocrFront"));
            if (!valor.trim() || valor === "") {
                validacion.errores.ocr = 'Debes ingresar un valor válido';
            }else{
                if(dataUser.modeloIdentificacion === "A" || dataUser.modeloIdentificacion === "B" || dataUser.modeloIdentificacion === "C"){
                    if (!esNumeros(valor) || valor.length !== 12) {
                        validacion.errores.ocr = 'Formato de OCR no válido';
                    } else {
                        validacion.correcto = true;
                    }
                }else{
                    if (!esNumeros(valor) || valor.length !== 13) {
                        validacion.errores.ocr = 'Formato de OCR no válido';
                    } else {
                        validacion.correcto = true;
                    }
                }
            }
            */
            break;
        case "curp":
            if (!valor.trim()) {
                validacion.errores.curp = 'Debes ingresar un valor válido';
            } else if (valor.length < 16 || !validarCurp(valor) || valor.length > 18) {
                validacion.errores.curp = 'Formato de CURP no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "vigencia":
            let today = new Date();
            let year = today.getFullYear();
            if (!valor.trim()) {
                validacion.errores.vigencia = 'Debes ingresar un valor válido';
            } else if ((valor.length !== 4)) {
                validacion.errores.vigencia = 'Revisa que el año sea 4 dígitos';
            } else if (!esNumeros(valor)) {
                validacion.errores.vigencia = 'Formato de vigencia no válido';
            } else if (Number(valor) < year) {
                validacion.errores.vigencia = 'Vigencia de identificación no válida';
            } else {
                validacion.correcto = true;
            }
            break;
        case "vigenciaPasaporte":
            let fechaActual = valor
            let dateParts = fechaActual.split("/");
            let fechaVigencia = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            if (!valor.trim()) {
                validacion.errores.vigenciaPasaporte = 'Debes ingresar un valor válido';
            } else if (fechaVigencia < Date.now()) {
                validacion.errores.vigenciaPasaporte = 'Vigencia de identificación no válida';
            } else if (moment(valor, formatosFecha, true).isValid()) {
                validacion.correcto = true;
            } else {
                validacion.errores.vigenciaPasaporte = 'Revisa el formato dd/mm/aaaa';
            }
            break;
        case "dni":
            if (!valor.trim()) {
                validacion.errores.dni = 'Debes ingresar un valor válido';
            } else if (valor.length < 3 || !esNumLetras(valor)) {
                validacion.errores.dni = 'Formato de DNI no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "codigoPostal":
            if (!valor.trim()) {
                validacion.errores.codigoPostal = 'Debes ingresar un valor válido';
            } else if (!esNumeros(valor)) {
                validacion.errores.codigoPostal = 'Código postal no válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "pais":
            if (!valor.trim()) {
                validacion.errores.pais = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "estado":
            if (!valor.trim()) {
                validacion.errores.estado = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "delegacion":
            if (!valor.trim()) {
                validacion.errores.delegacion = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "colonia":
            if (!valor.trim()) {
                validacion.errores.colonia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "otraColonia":
            if (!valor.trim()) {
                validacion.errores.otraColonia = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "calle":
            if (!valor.trim()) {
                validacion.errores.calle = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroExterior":
            if (!valor.trim()) {
                validacion.errores.numeroExterior = 'Debes ingresar un valor válido';
            } else {
                validacion.correcto = true;
            }
            break;
        case "numeroInterior":
            validacion.correcto = true;
            break;
        default:
            validacion.correcto = true;
            break;
    }
    //console.log("validacion",validacion);
    return validacion;
};

export const evento = (paso, tipo, dato, finished) => {
    let fecha = "";
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hour = date.getHours();
    let min = date.getMinutes();
    let seconds = date.getSeconds();
    if (month < 10) {
        fecha = `${year}-0${month}-${day}_${hour}:${min}:${seconds}`
    } else {
        fecha = `${year}-${month}-${day}_${hour}:${min}:${seconds}`
    }
    let uuidUser = localStorage.getItem("uuidUser");
    let uuidTrx = localStorage.getItem("uuidTrx");
    let apikeyOtorgante = localStorage.getItem("apikeyOtorgante");
    let uuidOtorgante = localStorage.getItem("uuidOtorgante");
    let objeto = {
        uuid_clt: uuidUser,
        uuid_trx: uuidTrx,
        uuid_oto: uuidOtorgante,
        apikey: apikeyOtorgante,
        paso: paso,
        tipo: tipo,
        dato: dato,
        finished: finished,
        time_stamp: fecha
    }
    enviarEvento(objeto).then(response => {
        //console.log('RESPONSE', response);
        return
    }).catch(error => console.log('ERROR', error));
}

export const endpointHolograma = (file, isIOS) => {
    let tipo = '';
    let informacion = {};
    enviarHologramaIdentificacion(file, isIOS).then(({ status, data: { payload } }) => {
        if (status === 200) {
            informacion.status = status;
            informacion.payload = payload;
            tipo = 'Success';
            evento('Captura Holograma', tipo, informacion, true);
        }
    }).catch(error => {
        console.log('ERROR', error);
        if (error.response) {
            let { data: { message }, status } = error.response
            informacion.mensaje = message;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Captura Holograma', tipo, informacion, false);
    })
}

export const getX = () => {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (innerWidth >= 424 || innerWidth < 424) {
        return 2;
    } else {
        return 2;
    }
}

export const getY = () => {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (innerWidth > 424) {
        return 24;
    } else if (innerWidth === 424) {
        if (innerHeight > 728) {
            return 24;
        } else {
            return 29;
        }
    } else if (innerWidth >= 412) {
        if (innerHeight > 728) {
            return 24;
        } else {
            return 32;
        }
    } else if (innerWidth >= 360) {
        if (innerHeight > 728) {
            return 24;
        } else {
            return 27;
        }
    } else {
        return 27;
    }
}

export const getWidth = () => {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (innerWidth >= 424) {
        return 97;
    } else if (innerWidth >= 412) {
        return 96;
    } else if (innerWidth >= 360) {
        return 96;
    } else {
        return 96;
    }
}

export const getHeight = () => {
    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    if (innerWidth >= 424) {
        return 45;
    } else if (innerWidth >= 412) {
        return 38;
    } else if (innerWidth >= 360) {
        if (innerHeight <= 640) {
            return 45;
        } else if (innerHeight <= 657) {
            return 37;
        } else if (innerHeight <= 664) {
            return 45;
        } else {
            return 35;
        }
    } else {
        return 35;
    }
}

export const flowIncompleted = async (objetoInformacion, flag) => {
    if (localStorage.getItem("completado") === "false") {
        let tipo = '';
        let informacion = {};
        let dataEvent = null;
        let trx_id = localStorage.getItem("uuidTrx");
        let company_id = localStorage.getItem("apikeyOtorgante");
        let client_id = localStorage.getItem("uuidUser");
        const dataOtorgante = (JSON.parse(localStorage.getItem("dataOtorgante")));
        const dataUser = (JSON.parse(localStorage.getItem("data_user")));

        let telefono = localStorage.getItem("movil") ? localStorage.getItem("movil") : '';
        let correo = localStorage.getItem("correoElectronico") ? localStorage.getItem("correoElectronico") : '';
        let fullName = dataUser ? obtenerValorUser(dataUser, "nombreCompleto", "") : '';

        let objetoStatus = {
            'trx_id': trx_id,
            'company_id': company_id,
            'client_id': client_id,
            'origin': "celd-cl",
            'nombreOtorgante': obtenerValorConfig(dataOtorgante, 'NOMBRE_CORTO', ""),
            'nombreCompleto': fullName,
            'telefono': telefono,
            'correoElectronico': correo,
            'bandera': flag
        }

        objetoFinal = {
            ...objetoStatus,
            ...objetoInformacion
        }

        dataEvent = JSON.parse(objetoInformacion.event)

        actualizarEstado(objetoFinal).then(({ status }) => {
            if (status === 200) {
                //console.log("Se actualizo status INIT");
                informacion.status = dataEvent.status;
                informacion.dataLifeCycle = objetoFinal;
                tipo = 'Update Cycle';
                evento(dataEvent.step, tipo, informacion, true);
            }
        }).catch((error) => {
            //console.log("No se pudo actualizar init Status. "+e);
            //setLoading(false)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error Update Cycle';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception Update Cycle';
            }
            evento(dataEvent.step, tipo, informacion, false);
        })
    } else {
        return
    }
}
/* Validación RENAPO */
const validarCurp = (curp) => {
    let result = true
    var expreg = /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/;
    //var result = input.match(expreg);
    if (!curp.match(expreg))
        result = false
    //console.log("RESULT",result);
    return result
}

export const validarRenapo = async () => {
    let jobLS = localStorage.getItem("jobRENAPO");
    let validacionRENAPO = localStorage.getItem("validacionRENAPO");
    let tipo = '';
    let informacion = {};
    //console.log(jobLS);
    if (jobLS && (validacionRENAPO === 'false')) {
        //console.log('VALIDACION_RENAPO', validacionRENAPO);
        obtenerResultadoRenapo(jobLS).then(async ({ data: { payload: { item } }, status, data: { payload } }) => {
            if (status === 200) {
                if (item && item.length > 0) {
                    informacion.status = status;
                    informacion.payload = payload;
                    //localStorage.setItem("validacionRENAPO", true);
                    let responseItem = item[0];
                    if (responseItem.status.trim() === 'VALIDADO') {
                        statusData('validacion_renapo', { validacion: true, status: responseItem.status });
                        localStorage.setItem("validacionRENAPO", true);
                        localStorage.removeItem('jobRENAPO');
                    } else if (responseItem.status.trim() === 'INVALIDO') {
                        statusData('validacion_renapo', { validacion: false, status: responseItem.status });
                        localStorage.setItem("validacionRENAPO", true);
                        localStorage.removeItem('jobRENAPO');
                    } else {
                        setTimeout(() => {
                            validarRenapo();
                        }, 3000);
                    }
                } else {
                    setTimeout(() => {
                        validarRenapo();
                    }, 3000);
                }
                evento('Validación RENAPO', 'Success', informacion, true);
            }
        }).catch(async error => {
            if (error.response) {
                let { data, status } = error.response
                informacion.data = data;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Validación RENAPO', tipo, informacion, false);
            setTimeout(() => {
                validarRenapo();
            }, 3000);
        });
    } else {
        if (!validacionRENAPO) encolarPeticionRenapo();
    }
}

export const encolarPeticionRenapo = () => {
    let tipo = '';
    let informacion = {};
    const dataUser = (JSON.parse(localStorage.getItem("data_user")));
    let curp = obtenerValorUser(dataUser, "curp", '');
    enviarRenapo(curp).then(({ status, data: { payload }, data: { payload: { job_id } } }) => {
        localStorage.setItem("jobRENAPO", job_id);
        localStorage.setItem("validacionRENAPO", false);
        informacion.status = status;
        informacion.payload = payload;
        evento('Encolar petición RENAPO', 'Success', informacion, true);
        setTimeout(() => {
            validarRenapo();
        }, 3000);
    }).catch(error => {
        if (error.response) {
            let { data: { message_client }, status } = error.response
            informacion.mensaje = message_client;
            informacion.status = status;
            tipo = 'Error';
        } else {
            informacion.error = error.toString();
            tipo = 'Exception';
        }
        evento('Encolar petición RENAPO', tipo, informacion, false);
        setTimeout(() => {
            validarRenapo();
        }, 3000);
    })
}

const obtenerDocumentos = async (paso, respuesta, flag) => {
    let tipo = '';
    let informacion = {};
    let res = await convertdataJSON();
    let event_t = await JSON.stringify(res);
    let json_final = {};
    let status = "FAIL"
    json_final = {
        "status": status,
        "step": "finished",
        "description": event_t,
        'response': respuesta,
        "finished": true
    }
    let event = JSON.stringify(json_final);
    getDocuments().then(async (response) => {
        informacion.payload = response.data.payload;
        evento('Obtener Documentos', 'Success', informacion, true);
        if (response.status === 200) {
            if (response.data.payload.links.length > 0) {
                let links = JSON.stringify(response.data.payload.links);
                localStorage.setItem('links', links);
            }
        }
        await flowIncompleted({ 'step': paso, 'event': event }, 'red');
    }).catch(async (error) => {
        if (error.response) {
            tipo = 'Exception';
        }
        evento('Obtener Documentos', tipo, informacion, false);
        await flowIncompleted({ 'step': paso, 'event': event }, 'red');
        //console.log("algun error documents");
    })
}

const convertdataJSON = () => {
    let jsonObj = {};
    jsonObj.uuidUser = localStorage.getItem("uuidUser") || '';
    jsonObj.uuidTrx = localStorage.getItem("uuidTrx") || '';
    jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
    jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
    jsonObj.data_user = JSON.parse(localStorage.getItem("data_user")) || {};
    jsonObj.links = JSON.parse(localStorage.getItem('links')) || {};
    return jsonObj;
}

/* Validación INE */

export const validarINE = async paso => {
    let ocrFront = null
    let dataOCRFrontLS = localStorage.getItem("ocrFront");
    if (dataOCRFrontLS) ocrFront = JSON.parse(dataOCRFrontLS);
    let jobLS = localStorage.getItem("jobINE");
    let validacionINE = localStorage.getItem("validacionINE");
    let tipo = '';
    let informacion = {};
    //console.log(jobLS);
    if (jobLS && (validacionINE === 'false')) {
        //console.log('VALIDACION_RENAPO', validacionRENAPO);
        obtenerResultadoINE(jobLS).then(async ({ data: { payload: { message } }, status, data: { payload } }) => {
            if (status === 200) {
                if (message && message.length > 0) {
                    informacion.status = status;
                    informacion.payload = payload;
                    let responseItem = message[0];
                    if (responseItem.estatus.trim() === 'VALIDADO') {
                        statusData('validacion_ine', { validacion: true, status: responseItem.estatus });
                        localStorage.setItem("validacionINE", true);
                        localStorage.removeItem('jobINE');
                    } else if (responseItem.estatus.trim() === 'INVALIDO') {
                        statusData('validacion_ine', { validacion: false, status: responseItem.estatus });
                        localStorage.setItem("validacionINE", true);
                        localStorage.removeItem('jobINE');
                    } else {
                        setTimeout(() => {
                            validarINE();
                        }, 3000);
                    }
                } else {
                    setTimeout(() => {
                        validarINE();
                    }, 3000);
                }
                evento('Validación INE', 'Success', informacion, true);
            }
        }).catch(async error => {
            if (error.response) {
                let { data, status } = error.response
                informacion.mensaje = data;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Validación INE', tipo, informacion, false);
            setTimeout(() => {
                validarINE();
            }, 3000);
        });
    } else {
        if (ocrFront.identificacionElectoral && !validacionINE) encolarPeticionINE();
    }
}

export const encolarPeticionINE = () => {
    let tipo = '';
    let informacion = {};
    const dataUser = (JSON.parse(localStorage.getItem("data_user")));
    let ocr = obtenerValorUser(dataUser, "ocr", '');
    let idCiudadano = obtenerValorUser(dataUser, "idCiudadano", '');
    let claveElector = obtenerValorUser(dataUser, "claveElector", '');
    let numeroEmision = obtenerValorUser(dataUser, "numeroEmision", '');
    let modeloIdentificacion = obtenerValorUser(dataUser, "modeloIdentificacion", '');
    enviarIne(ocr, idCiudadano, claveElector, numeroEmision, modeloIdentificacion).then(
        ({ status, data: { payload }, data: { payload: { message } } }) => {
            localStorage.setItem("jobINE", message.data);
            localStorage.setItem("validacionINE", false);
            informacion.status = status;
            informacion.payload = payload;
            evento('Encolar petición INE', tipo, informacion, true);
            setTimeout(() => {
                validarINE();
            }, 3000);

        }).catch(error => {
            if (error.response) {
                let { data, status } = error.response
                informacion.mensaje = data;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Encolar petición INE', tipo, informacion, false);
            setTimeout(() => {
                validarINE();
            }, 3000);
        })
}

export const sendZip = async (tipoEnvio, bandera) => {
    let dataOtorganteLS = localStorage.getItem("dataOtorgante");
    let semaforo = obtenerValorConfig(JSON.parse(dataOtorganteLS), "SEND_ZIP_SEMAFORO", false);
    if (semaforo) {
        let dataJSONr = await convertdataJSON();
        let tipo = '';
        let informacion = {};
        dataJSONr.tipo_envio = tipoEnvio;
        dataJSONr.semaforo = bandera;
        enviarZipAmarillos(dataJSONr).then((response) => {
            if (response.status === 200) {
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento('Envío Zip', 'Success', informacion, true);
            }
        }).catch((error) => {
            if (error.response) {
                let { data: { message_client }, status } = error.response
                informacion.mensaje = message_client;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Envío Zip', tipo, informacion, false);
        })
    } else {
        return
    }
}

export const validarOtorganteCDC = () => {
    let otorganteCDC = false;
    const dataOtorgante = (JSON.parse(localStorage.getItem("dataOtorgante")));
    const uuidOtorgante = localStorage.getItem("uuidOtorgante");
    const enable_send_mail = obtenerValorConfig(dataOtorgante, 'ENABLE_SEND_MAIL', "");
    const validacionCorreoCDC = (enable_send_mail && enable_send_mail === 'False') ? true : false;
    console.log("enable_send_mail", enable_send_mail);
    console.log("validacionCorreoCDC", validacionCorreoCDC);
    // if (uuidOtorgante === 'e89ba843-66e4-4f8f-92ae-a148fb751dba' || validacionCorreoCDC) {
    if (uuidOtorgante === 'd30934e0-d6ae-4f0b-b027-0e91a958ea55' || validacionCorreoCDC) {

        otorganteCDC = true;
    }
    return otorganteCDC;
}