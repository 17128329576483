import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { configStyle, obtenerValorConfig } from '../../services/configStyle'
import { actualizarEstado, enviarRenapo, enviarIne, consultarListasNegras } from '../../services/api';
import { isAndroid } from 'react-device-detect';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { hayExcepcion, agregarInfo, status, statusData, esEditable, formatoFechaOcr, formatoFechaCuatroDigitos, validarCampo, validarValoresFormulario, evento } from '../../services/data'
import Info from '../../components/encuentra_info';
import Loader from "../../components/loader";
import InfoIne from '../../components/info_ine';


let FormularioIneConsulta = (props) => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataOCRBack, setDataOCRBack] = useState({})
    const [dataOCRFront, setDataOCRFront] = useState({})
    const [focusNombre, setFocusNombre] = useState(false)
    const [focusNacimiento, setFocusNacimiento] = useState(false)
    const [focusCurp, setFocusCurp] = useState(false)
    const [focusVigencia, setFocusVigencia] = useState(false)
    const [focusClave, setfocusClave] = useState(false);
    const [focusRegistro, setfocusRegistro] = useState(false);
    const [focusEmision, setfocusEmision] = useState(false);
    const [focusOCR, setfocusOCR] = useState(false);
    const [dataUser, setDataUser] = React.useState([])
    const [inputActive, setInputActive] = useState(false)
    const [esPassport, setEsPassport] = useState(false);
    const [esIne, setEsIne] = useState(true);
    const [esCLaboral, setCLaboral] = useState(true);
    const [noOCR, setNoOCR] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [inputsValue, setInputsValue] = useState({});
    const [inputsErrors, setInputsErrors] = useState({});
    const [fechaListasNegras, setFechaListasNegras] = useState('');
    const [uuid, setUuid] = useState('');
    const [focusApellidoPaterno, setFocusApellidoPaterno] = useState(false);
    const [focusApellidoMaterno, setFocusApellidoMaterno] = useState(false);
    const [sendForm, setSendForm] = useState(false);
    const [esVigente, setEsVigente] = useState(true);
    const [encolarRENAPO, setEncolarRENAPO] = useState(false);
    const [encolarINE, setEncolarINE] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modeloIdentificacion, setModeloIdentificacion] = useState(false);
    const [showInfo, setShowInfo] = useState(false);

    useEffect(() => {

        let no_OCR = localStorage.getItem("intentsData")
        if (no_OCR !== null) {
            if (no_OCR === "OCR")
                setNoOCR(true)
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }

        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser)
        }
        let dataOCRFrontLS = localStorage.getItem("ocrFront")
        const ocrFront = JSON.parse(dataOCRFrontLS)
        if (dataOCRFrontLS != null) {
            setDataOCRFront(ocrFront);
            if (ocrFront.nombres)
                setInputsValue(inputsValue => ({ ...inputsValue, ["nombres"]: ocrFront.nombres || '' }));
            if (ocrFront.apellidoPaterno)
                setInputsValue(inputsValue => ({ ...inputsValue, ["apellidoPaterno"]: ocrFront.apellidoPaterno || '' }));
            if (ocrFront.apellidoMaterno)
                setInputsValue(inputsValue => ({ ...inputsValue, ["apellidoMaterno"]: ocrFront.apellidoMaterno || '' }));
            if (ocrFront.claveElector)
                setInputsValue(inputsValue => ({ ...inputsValue, ["clave"]: (ocrFront.claveElector).trim() }));
            if (ocrFront.anioRegistro)
                setInputsValue(inputsValue => ({ ...inputsValue, ["fechaRegistro"]: (ocrFront.anioRegistro).trim() }));
            if (ocrFront.numeroRegistro)
                setInputsValue(inputsValue => ({ ...inputsValue, ["numeroEmision"]: (ocrFront.numeroRegistro).trim() }));
            if (ocrFront.fechaNacimiento) {
                if (ocrFront.fechaNacimiento.length > 6 && ocrFront.fechaNacimiento.length < 10) {
                    let fechaOcr = formatoFechaCuatroDigitos(ocrFront.fechaNacimiento);
                    let fechaNacimiento = formatoFechaOcr(fechaOcr);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }));
                } else {
                    let fechaNacimiento = formatoFechaOcr(ocrFront.fechaNacimiento);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }));
                }
            }
            if (ocrFront.curp)
                if (ocrFront.curp.length > 14)
                    setInputsValue(inputsValue => ({ ...inputsValue, ["curp"]: (ocrFront.curp).trim() }));
            let vigenciaC = ''
            if (ocrFront.vigencia) {
                vigenciaC = "20" + ocrFront.vigencia.substr(-2);
                setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
            }

            setModeloIdentificacion('A');
            /* if (ocrFront.modeloIdentificacion){
                setModeloIdentificacion(ocrFront.modeloIdentificacion);
            }else{
                setModeloIdentificacion('A');
            } */

            /* Listas negras */
            /* if (ocrFront.nombres)
                setNombres(ocrFront.nombres);
            if (ocrFront.apellidoMaterno)
                setApellidoMaterno(ocrFront.apellidoMaterno);
            if (ocrFront.apellidoPaterno)
                setApellidoPaterno(ocrFront.apellidoPaterno);
            if (ocrFront.fechaNacimiento) {
                let fecha = ocrFront.fechaNacimiento;
                let dateArray = fecha.split("/");
                let fechaNacimiento = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
                setFechaListasNegras(fechaNacimiento);
            } */
        }

        let dataOCRBackLS = localStorage.getItem("ocrBack");

        if (dataOCRBackLS) {
            setDataOCRBack(JSON.parse(dataOCRBackLS));
            const ocrBack = JSON.parse(dataOCRBackLS);
            let vigenciaC = '';

            if (ocrBack.ineVigencia) {
                vigenciaC = ocrBack.ineVigencia;
                let formatosP = ['DD/MM/YYYY', 'DD/MM/YY'];
                if (moment(vigenciaC, formatosP, true).isValid()) {
                    // setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
                    setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: "20" + vigenciaC.substr(-2) }));
                }
            } else {
                if (ocrFront.vigencia) {
                    if (vigenciaC) {
                        setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: "20" + vigenciaC.substr(-2) }));
                    } else {
                        let vi = ""
                        if (ocrFront.vigencia.includes(' '))
                            vi = ocrFront.vigencia.replaceAll(' ', '/');
                        else
                            vi = ocrFront.vigencia;
                        setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vi }));
                    }
                } else {
                    if (ocrFront.vigencia)
                        vigenciaC = "20" + ocrFront.vigencia.substr(-2);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
                }
            }
            // if (ocrBack.cicMRZ)
            //     setInputsValue(inputsValue => ({ ...inputsValue, ["cic"]: (ocrBack.cicMRZ).trim() }));
            let ocr = '';
            if (ocrBack.seccionMRZ)
                ocr = ocrBack.seccionMRZ.trim();
            if (ocrBack.ciudadanoMRZ)
                ocr = `${ocr}${ocrBack.ciudadanoMRZ.trim()}`;
            setInputsValue(inputsValue => ({ ...inputsValue, ["ocr"]: ocr }));
        }
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante]);

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser]);

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoPersonal = infoFormulario;
        statusData("datos_personales_ocr", jsonObj)
    }

    const handleChange = (event) => {
        event.persist();
        let validacion = null;
        let value = event.target.value.trim().toUpperCase();
        if (event.target.name === 'curp') {
            let maxLength = 18;
            if (event.target.value.length > maxLength) {
                value = value.substr(0, maxLength);
                event.target.value = value;
            }
            validacion = validarCampo(value, event.target.name);
        } else {
            validacion = validarCampo(value, event.target.name);
        }
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }));
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }
    };

    const validarFormulario = (event) => {
        if (event) event.persist(); event.preventDefault(); event.stopPropagation();
        setSendForm(true);
        // console.log("inputsValue",inputsValue);
        let continuar = validarValoresFormulario(inputsValue);
        if (continuar.correcto) {
            let lengthString = inputsValue.ocr.length;
            let idCiudadano = inputsValue.ocr.substring(4, lengthString);
            setLoading(true);
            if (obtenerValorConfig(dataOtorgante, 'ENABLE_RENAPO', "false") === "true") {
                let tipo = '';
                let informacion = {};
                enviarRenapo(inputsValue.curp).then(({ status, data: { payload }, data: { payload: { job_id } } }) => {
                    localStorage.setItem("jobRENAPO", job_id);
                    localStorage.setItem("validacionRENAPO", false);
                    informacion.status = status;
                    informacion.payload = payload;
                    evento('Encolar petición RENAPO', 'Success', informacion, true);
                }).catch(error => {
                    if (error.response) {
                        let { data: { message_client }, status } = error.response
                        informacion.mensaje = message_client;
                        informacion.status = status;
                        tipo = 'Error';
                    } else {
                        informacion.error = error.toString();
                        tipo = 'Exception';
                    }
                    evento('Encolar petición RENAPO', tipo, informacion, false);
                }).finally(() => {
                    setEncolarRENAPO(true);
                });
            }

            if (obtenerValorConfig(dataOtorgante, 'ENABLE_INE', "false") === "true" && esIne) {
                let tipo = '';
                let informacion = {};
                enviarIne(inputsValue.ocr, idCiudadano, inputsValue.clave, inputsValue.numeroEmision, modeloIdentificacion).then(
                    ({ status, data: { payload }, data: { payload: { message } } }) => {
                        localStorage.setItem("jobINE", message.data);
                        localStorage.setItem("validacionINE", false);
                        informacion.status = status;
                        informacion.payload = payload;
                        evento('Encolar petición INE', tipo, informacion, true);
                    }).catch(error => {
                        if (error.response) {
                            let { data, status } = error.response
                            informacion.data = data;
                            informacion.status = status;
                            tipo = 'Error';
                        } else {
                            informacion.error = error.toString();
                            tipo = 'Exception';
                        }
                        evento('Encolar petición INE', tipo, informacion, false);
                    }).finally(() => {
                        setEncolarINE(true);
                    });
            }
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'OK' }, true);
            let infoFormulario = []
            let fechaActual = inputsValue.vigencia;
            agregarInfo(dataUser, { description: "nombres", value: inputsValue.nombres });
            infoFormulario.push({ description: "nombres", value: inputsValue.nombres })
            agregarInfo(dataUser, { description: 'apellidoPaterno', value: inputsValue.apellidoPaterno });
            infoFormulario.push({ description: "apellidoPaterno", value: inputsValue.apellidoPaterno })
            agregarInfo(dataUser, { description: "apellidoMaterno", value: inputsValue.apellidoMaterno });
            infoFormulario.push({ description: "apellidoMaterno", value: inputsValue.apellidoMaterno });
            let nombreCompleto = `${inputsValue.nombres} ${inputsValue.apellidoPaterno} ${inputsValue.apellidoMaterno}`
            agregarInfo(dataUser, { description: "nombreCompleto", value: nombreCompleto });
            infoFormulario.push({ description: "nombreCompleto", value: nombreCompleto });
            let fechaNacimiento = document.getElementById("fechaNacimiento").value;
            agregarInfo(dataUser, { description: "nacimiento", value: fechaNacimiento });
            infoFormulario.push({ description: "nacimiento", value: fechaNacimiento })
            agregarInfo(dataUser, { description: "curp", value: inputsValue.curp });
            infoFormulario.push({ description: "curp", value: inputsValue.curp });
            agregarInfo(dataUser, { description: "claveElector", value: inputsValue.clave });
            infoFormulario.push({ description: "claveElector", value: inputsValue.clave });
            agregarInfo(dataUser, { description: "fechaRegistro", value: inputsValue.fechaRegistro });
            infoFormulario.push({ description: "fechaRegistro", value: inputsValue.fechaRegistro });
            agregarInfo(dataUser, { description: "numeroEmision", value: inputsValue.numeroEmision });
            infoFormulario.push({ description: "numeroEmision", value: inputsValue.numeroEmision });
            agregarInfo(dataUser, { description: "ocr", value: inputsValue.ocr });
            infoFormulario.push({ description: "ocr", value: inputsValue.ocr });
            agregarInfo(dataUser, { description: "idCiudadano", value: idCiudadano });
            agregarInfo(dataUser, { description: "modeloIdentificacion", value: modeloIdentificacion });
            let today = new Date();
            let year = today.getFullYear();
            if (Number(fechaActual) >= year) {
                infoFormulario.push({ description: "vigencia", value: "31/12/20" + inputsValue.vigencia.substr(-2) })
                agregarInfo(dataUser, { description: "vigencia", value: "31/12/20" + inputsValue.vigencia.substr(-2) });
                fechaActual = "20" + inputsValue.vigencia.substr(-2) + "/12/31";
                let fechaV = new Date(fechaActual);
                if (fechaV >= Date.now()) {
                    sendData(infoFormulario);
                    /* setTimeout(() => {
                            history.push('/identificacion_edit');
                    }, 300); */
                } else {
                    setEsVigente(false)
                }
            } else {
                setEsVigente(false)
            }
        } else {
            evento('Datos personales OCR', 'Click', { description: 'MIS DATOS SON CORRECTOS', status: 'Error', Errores: continuar.errores }, false);
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...continuar.errores }));
            setSendForm(false);
        }
    }

    const handleKeyUp = (event) => event.target.value = event.target.value.toUpperCase();

    useEffect(() => {
        if (encolarRENAPO && encolarINE) {
            setLoading(false);
            setTimeout(() => {
                history.push('/identificacion_edit');
            }, 300);
        }
        return () => { };
    }, [encolarRENAPO, encolarINE]);

    return (
        <Fragment>
            <form id="FormularioIneConsulta">
                <div className="ocr_data_display animate__animated">
                    <div className={["form-group", "bmd-form-group", ((focusNombre || inputsValue.nombres !== "") ? "is-focused" : ""), inputsErrors.nombres && 'error'].join(" ")}>
                        <label htmlFor="nombres" className="bmd-label-floating">Nombre(s):</label>
                        <input type="text" className="form-control none_border" id="nombres" name="nombres" defaultValue={inputsValue.nombres} onChange={handleChange} onFocus={e => {
                            setFocusNombre(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (inputsValue.nombres === "") {
                                setFocusNombre(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.nombres && (
                            <span id="ht-nombres" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nombres}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusApellidoPaterno || inputsValue.apellidoPaterno !== "") ? "is-focused" : ""), inputsErrors.apellidoPaterno && 'error'].join(" ")}>
                        <label htmlFor="apellidoPaterno" className="bmd-label-floating">Apellido Paterno:</label>
                        <input type="text" className="form-control none_border" id="apellidoPaterno" name="apellidoPaterno" defaultValue={inputsValue.apellidoPaterno} onChange={handleChange} onFocus={e => {
                            setFocusApellidoPaterno(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (inputsValue.nombres === "") {
                                setFocusApellidoPaterno(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.apellidoPaterno && (
                            <span id="ht-apellidoPaterno" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.apellidoPaterno}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusApellidoMaterno || inputsValue.apellidoMaterno !== "") ? "is-focused" : ""), inputsErrors.apellidoMaterno && 'error'].join(" ")}>
                        <label htmlFor="apellidoMaterno" className="bmd-label-floating">Apellido Materno:</label>
                        <input type="text" className="form-control none_border" id="apellidoMaterno" name="apellidoMaterno" defaultValue={inputsValue.apellidoMaterno} onChange={handleChange} onFocus={e => {
                            setFocusApellidoMaterno(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (inputsValue.apellidoMaterno === "") {
                                setFocusApellidoMaterno(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.apellidoMaterno && (
                            <span id="ht-apellidoMaterno" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.apellidoMaterno}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusNacimiento || inputsValue.fechaNacimiento !== "") ? "is-focused" : ""), inputsErrors.fechaNacimiento && 'error'].join(" ")}>
                        <label htmlFor="fechaNacimiento" className="bmd-label-floating">Fecha de nacimiento <small>(DD/MM/AAAA)</small>:</label>
                        <NumberFormat format="##/##/####" className="form-control none_border" id="fechaNacimiento" name="fechaNacimiento" value={inputsValue.fechaNacimiento} onChange={handleChange} onFocus={e => {
                            setFocusNacimiento(true)
                            setInputActive(true)
                        }
                        } onBlur={e => {
                            if (inputsValue.fechaNacimiento === "") {
                                setFocusNacimiento(false)
                            }
                            setInputActive(false)
                        }} />
                        {inputsErrors.fechaNacimiento && (
                            <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaNacimiento}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusClave || inputsValue.clave !== "") ? "is-focused" : ""), inputsErrors.clave && 'error'].join(" ")}>
                        <label htmlFor="clave" className="bmd-label-floating">Clave de elector:*Dato frontal</label>
                        <div className="display_flex">
                            <input type="text" className="form-control none_border" id="clave" name="clave" maxLength="18" defaultValue={inputsValue.clave} onChange={handleChange} onFocus={e => {
                                setfocusClave(true)
                            }} onBlur={e => {
                                if (inputsValue.clave === "") {
                                    setfocusClave(false)
                                }
                            }} />
                            <button type="button" className={['button_info', inputsErrors.clave && 'red_border_bottom'].join(" ")} onClick={() => setShowInfo(true)}><img className="info_icon" src="../images/info_icon.svg" alt="" /></button>
                        </div>
                        {inputsErrors.clave && (
                            <span id="ht-clave" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.clave}</span>
                        )}
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className={["form-group", "bmd-form-group", ((focusRegistro || inputsValue.fechaRegistro !== "") ? "is-focused" : ""), inputsErrors.fechaRegistro && 'error'].join(" ")}>
                                <label htmlFor="fechaRegistro" className="bmd-label-floating">Año de registro:</label>
                                <div className="display_flex">
                                    <input type="text" className="form-control none_border" id="fechaRegistro" name="fechaRegistro" maxLength="4" value={inputsValue.fechaRegistro || ''} onChange={handleChange} onFocus={e => {
                                        setfocusRegistro(true)
                                    }
                                    } onBlur={e => {
                                        if (inputsValue.fechaRegistro === "") {
                                            setfocusRegistro(false)
                                        }
                                    }} />
                                    <button type="button" className={['button_info', inputsErrors.fechaRegistro && 'red_border_bottom'].join(" ")} onClick={() => setShowInfo(true)}><img className="info_icon" src="../images/info_icon.svg" alt="" /></button>
                                </div>
                                {inputsErrors.fechaRegistro && (
                                    <span id="ht-registro" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaRegistro}</span>
                                )}
                            </div>
                        </div>
                        <div className="col">
                            <div className={["form-group", "bmd-form-group", ((focusEmision || inputsValue.numeroEmision !== "") ? "is-focused" : ""), inputsErrors.numeroEmision && 'error'].join(" ")}>
                                <label htmlFor="numeroEmision" className="bmd-label-floating">Número de emisión:</label>
                                <div className="display_flex">
                                    <input type="text" className="form-control none_border" id="numeroEmision" name="numeroEmision" value={inputsValue.numeroEmision || ''} maxLength="2" onChange={handleChange} onFocus={e => {
                                        setfocusEmision(true)
                                    }
                                    } onBlur={e => {
                                        if (inputsValue.numeroEmision === "") {
                                            setfocusEmision(false)
                                        }
                                    }} />
                                    <button type="button" className={['button_info', inputsErrors.numeroEmision && 'red_border_bottom'].join(" ")} onClick={() => setShowInfo(true)}><img className="info_icon" src="../images/info_icon.svg" alt="" /></button>
                                </div>
                                {inputsErrors.numeroEmision && (
                                    <span id="ht-emision" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.numeroEmision}</span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={["form-group", "bmd-form-group", ((focusOCR || inputsValue.ocr !== "") ? "is-focused" : ""), inputsErrors.ocr && 'error'].join(" ")}>
                        <label htmlFor="ocr" className="bmd-label-floating">OCR:<small> *Dato reverso</small></label>
                        <div className="display_flex">
                            <input type="text" className="form-control none_border" id="ocr" name="ocr" maxLength="13" defaultValue={inputsValue.ocr} onChange={handleChange} onFocus={e => {
                                setfocusOCR(true)
                            }} onBlur={e => {
                                if (inputsValue.ocr === "") {
                                    setfocusOCR(false)
                                }
                            }} />
                            <button type="button" className={['button_info', inputsErrors.ocr && 'red_border_bottom'].join(" ")} onClick={() => setShowInfo(true)}><img className="info_icon" src="../images/info_icon.svg" alt="" /></button>
                        </div>
                        {inputsErrors.ocr && (
                            <span id="ht-OCR" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.ocr}</span>
                        )}

                    </div>

                    <div className={["form-group", "bmd-form-group", ((focusCurp || inputsValue.curp !== "") ? "is-focused" : ""), inputsErrors.curp && 'error'].join(" ")}>
                        <label htmlFor="curp" className="bmd-label-floating">CURP:</label>
                        <input type="text" className="form-control uppercase none_border" id="curp" name="curp" defaultValue={inputsValue.curp} maxLength="19" onChange={handleChange} onKeyUp={handleKeyUp} onFocus={e => {
                            setFocusCurp(true)
                        }} onBlur={e => {
                            if (inputsValue.curp === "") {
                                setFocusCurp(false)
                            }
                        }} />
                        {inputsErrors.curp && (
                            <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.curp}</span>
                        )}
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className={["form-group", "bmd-form-group", ((focusVigencia || inputsValue.vigencia !== "") ? "is-focused" : ""), inputsErrors.vigencia && 'error'].join(" ")}>
                                <label htmlFor="vigencia" className="bmd-label-floating">Vencimiento identificación:</label>
                                <div className="display_flex">
                                    <input type="text" className="form-control none_border" id="vigencia" name="vigencia" maxLength="4" defaultValue={inputsValue.vigencia} onChange={handleChange} onFocus={e => {
                                        setFocusVigencia(true)
                                    }} onBlur={e => {
                                        if (inputsValue.vigencia === "") {
                                            setFocusVigencia(false)
                                        }
                                    }} />
                                    <button type="button" className={['button_info', inputsErrors.vigencia && 'red_border_bottom'].join(" ")} onClick={() => setShowInfo(true)}><img className="info_icon" src="../images/info_icon.svg" alt="" /></button>
                                </div>
                                {inputsErrors.vigencia && (
                                    <span id="ht-vigencia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.vigencia}</span>
                                )}
                            </div>
                        </div>
                        <div className="col">

                        </div>
                    </div>
                    <Info />
                </div>
                <div className="action_buttons animate__animated">
                    <button type="button" className={["btn btn-raised btn-primary forcewidth100 main_bg_color"].join(" ")} onClick={event => validarFormulario(event)}>MIS DATOS SON CORRECTOS</button>
                    <Link to={{
                        pathname: "/identificacion",
                        state: { passport: esPassport }
                    }} className="btn btn-primary forcewidth100 main_color">CAPTURAR NUEVAMENTE</Link>
                </div>
            </form>
            {(!esVigente) && (
                <div className="err_finale  animated slideInUp">
                    <div className="center_checks">
                        <h5>Identificación no valida </h5>
                        <p>La <b>vigencia</b> de tu identificación <b>{inputsValue.vigencia}</b> no es valida, por favor <b>renueva tu identificación</b> e intenta otro día, si crees que es un error <b>intenta capturarla nuevamente</b>.
                        </p>
                        <br />
                        <div className="action_buttons noscroll_screen">
                            <Link to={{
                                pathname: "/" + apiKey
                            }} className="btn btn-secondary forcewidth100" onClick={e => evento('Datos personales OCR', 'Click', { description: 'TERMINAR' }, true)}>TERMINAR</Link>
                            <Link to={{
                                pathname: "/identificacion",
                                state: { passport: esPassport }
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color" onClick={e => evento('Datos personales OCR', 'Click', { description: 'CAPTURAR NUEVAMENTE' }, true)}>CAPTURAR NUEVAMENTE</Link>
                        </div>
                    </div>
                </div>)}
            {loading && <Loader />}
            {showInfo && <InfoIne close={() => setShowInfo(false)} />}
        </Fragment>
    )
}

export default FormularioIneConsulta