import React, { useState, useEffect, Fragment } from "react";
import Header from '../components/headerNA';
import Ayuda from '../pages/ayuda';

const LoaderOCR = (props) => {

    const [stateActionA, setStateActionA] = useState(true);
    const [stateActionB, setStateActionB] = useState(true);
    const [stateActionC, setStateActionC] = useState(true);
    const [stateActionD, setStateActionD] = useState(true);
    const [stateActionE] = useState(true);
    const [stateWord, setStateWord] = useState(true);
    let tiempo0 = 500;
    let tiempo1 = 800;
    let tiempo2 = 1000;

    useEffect(() => {
        if (props.side == "back") {
            //console.log("time: " + tiempo0, tiempo1, tiempo2);
            setStateWord(false);
            tiempo0 = 500;
            tiempo1 = 500;
            tiempo2 = 1000;
        }
        setTimeout(() => {
            setStateActionA(false);
            setTimeout(() => {
                setStateActionB(false);
                setTimeout(() => {
                    setStateActionC(false);
                    setTimeout(() => {
                        setStateActionD(false);
                        setTimeout(() => {
                            //setStateActionE(false);
                        }, 1000)
                    }, 800)
                }, tiempo2)
            }, tiempo1)
        }, tiempo0)
    }, [])

    return (
        <Fragment>
            <div className="id_progress  animate__animated animate__slideInUp">
                <Header show={() => props.show()} />

                <div className="center_checks">
                    <h5>¡Espera unos segundos! <br /> Estamos analizando tu captura.</h5>

                    <div className={["inline_analisis", "animate__animated", "animate__fadeIn", "action_a", ((stateActionA) ? "active_v" : "done_v")].join(" ")}>
                        <div className="inline_loader">
                            <div className="ispinner ispinner-large">
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                            </div>
                        </div>
                        <img src="/images/check_36.png" width="24" alt="" />
                        <p>Identificando tu documento</p>
                    </div>

                    <div className={["inline_analisis", "animate__animated", "animate__fadeIn", "action_b", ((stateActionB) ? "active_v" : "done_v")].join(" ")}>
                        <div className="inline_loader">
                            <div className="ispinner ispinner-large">
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                            </div>
                        </div>
                        <img className="" src="/images/check_36.png" width="24" alt="" />
                        <p>Detectando los bordes de tu documento</p>
                    </div>

                    <div className={["inline_analisis", "animate__animated", "animate__fadeIn", "action_c", ((stateActionC) ? "active_v" : "done_v")].join(" ")}>
                        <div className="inline_loader">
                            <div className="ispinner ispinner-large">
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                            </div>
                        </div>
                        <img src="/images/check_36.png" width="24" alt="" />
                        <p>{[(stateWord) ? "Busqueda de rostro" : "Reconocimiento la zona de lectura mecánica"].join("")}</p>
                    </div>

                    <div className={["inline_analisis", "animate__animated", "animate__fadeIn", "action_d", ((stateActionD) ? "active_v" : "done_v")].join(" ")}>
                        <div className="inline_loader">
                            <div className="ispinner ispinner-large">
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                            </div>
                        </div>
                        <img src="/images/check_36.png" width="24" alt="" />
                        <p>Detectando texto en tu documento</p>
                    </div>

                    <div className={["inline_analisis", "animate__animated", "animate__fadeIn", "action_e", ((stateActionE) ? "active_v" : "done_v")].join(" ")}>
                        <div className="inline_loader">
                            <div className="ispinner ispinner-large">
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                            </div>
                        </div>
                        <img src="/images/check_36.png" width="24" alt="" />
                        <p>Extrayendo tus datos</p>
                    </div>

                </div>
            </div>
        </Fragment>
    );
}

export default LoaderOCR;